import Avatar from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';
import { Select } from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';

import { useGetCurrentCandidate, useUpdateCandidateOptimistically } from '@/hooks/candidate';
import { useGetCurrentUser } from '@/hooks/user';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getFullName } from '@/utils/string';

import { CANDIDATE_OPEN_TO_WORK } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

import LoadingCard from './loading-card';

const ProfileCard = () => {
  const { data: candidate, isLoading: isCandidateLoading } = useGetCurrentCandidate({});
  const { data: user, isLoading: isUserLoading } = useGetCurrentUser({});
  const { mutateAsync: updateCandidateOptimistically } = useUpdateCandidateOptimistically({});

  const onWorkAvailableChange = async (checked: boolean) => {
    await updateCandidateOptimistically({
      body: { open_to_work: checked ? CANDIDATE_OPEN_TO_WORK.YES : CANDIDATE_OPEN_TO_WORK.NO },
    });
  };

  const onLookingForChange = async (looking_for: string) => {
    await updateCandidateOptimistically({ body: { looking_for } });
  };

  const { data: availabilityList, isLoading: isAvailabilityLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.LOOKING_FOR,
      },
    },
  });

  if (isCandidateLoading || isUserLoading) return <LoadingCard />;

  return (
    <Card className="space-y-6">
      <div className="-ml-2.5 flex items-center gap-4">
        <Avatar
          src={user?.image_url}
          variant={'bordered'}
          size={'2xl'}
        />
        <div>
          <p className="text-xl font-semibold">{getFullName(user)}</p>
          <p className="text-sm text-primary-dark-40">{user?.email}</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <Switch
          defaultChecked={candidate?.open_to_work === CANDIDATE_OPEN_TO_WORK.YES}
          onCheckedChange={onWorkAvailableChange}
        />
        <span className="font-semibold">Available for work</span>
      </div>
      <Select
        options={
          availabilityList?.items.map((s) => s.description).filter((s): s is string => s !== undefined && s !== null) ||
          []
        }
        placeholder="Your Availability"
        selected={candidate?.looking_for || null}
        onChange={onLookingForChange}
        isLoading={isAvailabilityLoading}
      />
    </Card>
  );
};

export default ProfileCard;
