import { useEffect, useState } from 'react';
import { UilBookmark } from '@iconscout/react-unicons';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';

import NoData from '@/components/no-data';
import RoleCard from '@/components/roles/role-card';
import RolesFilter from '@/components/roles/roles-filter';

import { useGetCurrentCandidateMatches } from '@/hooks/candidate';
import useFilters from '@/hooks/useFilters';

import { TCandidatePostsFilters, TCandidatePostsSort } from '@/services/candidate';

import { sortByToQuery } from '@/utils/table-sort-mapper';

import { PORTAL_IDS } from '@/constants/portal-ids';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/roles/')({
  component: Matched,
  validateSearch: () => ({}) as TCandidatePostsFilters,
});

const MatchesLoading = () => {
  return (
    <div className="grid grid-cols-1 gap-x-9 gap-y-8 xl:grid-cols-2 3xl:grid-cols-3">
      {new Array(6).fill(null).map((_, index) => (
        <Skeleton
          key={index}
          className="h-[300px] rounded-lg"
        />
      ))}
    </div>
  );
};

const PortalButtons = () => {
  const navigate = useNavigate();
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);
  const { filters, setFilters } = useFilters<TCandidatePostsFilters>(Route.id);

  useEffect(() => {
    setPortalNode(document.getElementById(PORTAL_IDS.rolesButtons));
  }, []);

  if (!portalNode) return null;

  return createPortal(
    <div className="flex items-center gap-4">
      <Button
        variant="tertiary"
        onClick={() =>
          navigate({
            to: '/candidate/saved',
          })
        }
      >
        <span>Saved</span>
        <UilBookmark className="size-[1.125rem] text-primary-dark-100" />
      </Button>
      <RolesFilter
        filters={filters}
        setFilters={setFilters}
        usePortalForAppliedFilters
      />
    </div>,
    portalNode
  );
};

const InnerMatched = () => {
  const { filters } = useFilters<TCandidatePostsFilters>(Route.id);
  const sortQuery = sortByToQuery<TCandidatePostsSort>(filters.sortBy);

  const queryKey = [filters];

  const { data: roles, isPending } = useGetCurrentCandidateMatches({
    params: {
      query: {
        limit: '20',
        offset: '0',
        q: filters.query,
        ...(sortQuery && {
          sort: sortQuery.id,
          order: sortQuery.order,
        }),
      },
    },
    reactQueryOptions: {
      queryKey: queryKey,
    },
  });

  if (isPending) return <MatchesLoading />;

  if (roles?.items.length === 0)
    return (
      <NoData
        title="No new matches"
        description={
          'You don’t have any new matches.\nTake a look at your profile to see if there are any changes that you could make to stand out.'
        }
        isSearchResults={!!filters.query}
      />
    );

  return (
    <div className="grid grid-cols-1 gap-x-9 gap-y-8 xl:grid-cols-2 3xl:grid-cols-3">
      {roles?.items?.map((role) => (
        <RoleCard
          key={role.id}
          role={role}
          showSaveButton
          queryKey={queryKey}
        />
      ))}
    </div>
  );
};

function Matched() {
  return (
    <>
      <InnerMatched />
      <PortalButtons />
    </>
  );
}
