import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { parse, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Select } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';

import TimeSlotBooker, { TimeSlot } from '@/components/time-slot-booker';

import { useGetPostEventsById } from '@/hooks/post';

import { rescheduleMeetingSchema, TRescheduleMeetingFormData } from '@/validation-schemas/reschedule-meeting-schema';

const RescheduleForm: React.FC<{ onSubmit: (data: TRescheduleMeetingFormData) => void }> = ({ onSubmit }) => {
  const form = useForm<TRescheduleMeetingFormData>({
    resolver: zodResolver(rescheduleMeetingSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  return (
    <FormProvider {...form}>
      <Form className="space-y-4">
        <FormField
          name="reason"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Reason</FormLabel>
              <FormControl>
                <Select
                  options={['Personal reasons', '  I have an appointment', 'No longer interested in the role']}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.reason?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="description"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter description here..."
                  {...field}
                  error={!!errors?.description?.message}
                  rows={4}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>
      </AlertDialogFooter>
    </FormProvider>
  );
};

const RescheduleButton: React.FC<{ postId: number; disabled?: boolean; companyName: string }> = ({
  disabled,
  companyName,
  postId,
}) => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<TRescheduleMeetingFormData>();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(null);

  const { data } = useGetPostEventsById({
    params: {
      path: {
        postId: postId.toString(),
      },
    },
  });

  const events: TimeSlot[] =
    data
      ?.filter(({ slot_date, time_slots }) => !!slot_date && !!time_slots)
      ?.map(({ slot_date, time_slots }) => {
        const baseDate = parseISO(slot_date!);

        const [startTimeStr, endTimeStr] = time_slots!.split(' - ');

        const startTime = parse(startTimeStr, 'HHmm', baseDate);
        const endTime = parse(endTimeStr, 'HHmm', baseDate);

        // Return the result as an object with startTime and endTime as Date objects
        return {
          startTime,
          endTime,
        };
      }) || [];

  const onContinueClick = (e: React.MouseEvent) => {
    if (step === 3) return;

    e.preventDefault();
    setStep((prev) => prev + 1);
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <RescheduleForm
          onSubmit={(data) => {
            setFormData(data);
            setStep((prev) => prev + 1);
          }}
        />
      );
    }
    if (step === 2) {
      return (
        <TimeSlotBooker
          events={events}
          selectedSlot={selectedTimeSlot}
          onSlotSelect={setSelectedTimeSlot}
        />
      );
    }
    return null;
  };

  const renderDescription = () => {
    if (step === 1) {
      return 'Please tell us why you would you can’t make this interview time?';
    }
    if (step === 2) {
      return 'We understand that sometimes life gets in the way but please be cautious rescheduling more than once.';
    }
    if (step === 3) {
      return (
        <>
          <p>You have successfully rescheduled your interview with {companyName}! We wish you the best of luck.</p>
          <br />
          <p>Make sure to check your notifications for any updates!</p>
        </>
      );
    }
    return null;
  };

  const renderTitle = () => {
    if (step === 1) {
      return 'Reschedule';
    }
    if (step === 2) {
      return 'Book a new slot';
    }
    if (step === 3) {
      return 'Rescheduled';
    }
    return null;
  };

  const renderFooter = () => {
    if (step === 1) return;

    if (step === 2) {
      return (
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            onClick={(e) => {
              console.log(selectedTimeSlot, formData);
              onContinueClick(e);
            }}
            disabled={!selectedTimeSlot}
          >
            Book now
          </Button>
        </AlertDialogFooter>
      );
    }

    return (
      <AlertDialogFooter>
        <AlertDialogAction>Continue</AlertDialogAction>
      </AlertDialogFooter>
    );
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        setSelectedTimeSlot(null);
        setTimeout(() => setStep(1), 200);
      }}
    >
      <AlertDialogTrigger asChild>
        <Button
          variant="tertiary"
          disabled={disabled}
          className="w-full"
        >
          Reschedule
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="gap-6">
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant={step > 1 ? 'success' : null}>{renderTitle()}</AlertDialogTitle>
          <AlertDialogDescription className="pb-0">{renderDescription()}</AlertDialogDescription>
        </AlertDialogHeader>
        {renderContent()}
        {renderFooter()}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RescheduleButton;
