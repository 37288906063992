import { UilArrowLeft } from '@iconscout/react-unicons';
import { ToOptions, useNavigate } from '@tanstack/react-router';

import { Button } from './ui/button';

interface IBackButtonProps {
  backRoute: ToOptions['to'];
}

const BackButton: React.FC<IBackButtonProps> = ({ backRoute }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="tertiary"
      onClick={() => navigate({ to: backRoute })}
    >
      <UilArrowLeft className="size-[1.125rem] text-primary-dark-100" />
      <span>Back</span>
    </Button>
  );
};

export default BackButton;
