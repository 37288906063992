import { format } from 'date-fns';

import { TAdditionalLanguageFormData, TDomainExperienceFormData } from '@/validation-schemas/coupled-fields.schema';

export function getArrayFromString(value: string | null) {
  if (!value) return [];
  if (value === '') return [];
  return value.split(';');
}

export function getFormattedDataForAPI(object: { [key: string]: string | string[] | number | null | undefined }): {
  [key: string]: string | number | null | undefined;
} {
  const result: { [key: string]: string | number | null | undefined } = {};

  for (const [key, value] of Object.entries(object)) {
    if (Array.isArray(value)) {
      result[key] = value.join(';');
    } else if (typeof value === 'number') {
      result[key] = value;
    } else if (value === undefined) {
      result[key] = null;
    } else {
      result[key] = value;
    }
  }

  return result;
}

export function getFormattedAdditionalLanguagesForAPI(list: TAdditionalLanguageFormData[]): { languages: string } {
  const formattedAdditionalLanguages: { languages: string } = { languages: '' };

  list.forEach((language) => {
    formattedAdditionalLanguages.languages += `${language.language} - ${language.proficiency};`;
  });

  return formattedAdditionalLanguages;
}

export function parseFormattedAdditionalLanguages(languages: string): TAdditionalLanguageFormData[] {
  if (!languages) return [];
  const languagesList: TAdditionalLanguageFormData[] = [];

  const languageEntries = languages.split(';').filter(Boolean);

  languageEntries.forEach((entry) => {
    const [language, proficiency] = entry.split(' - ');
    if (language && proficiency) {
      languagesList.push({ language, proficiency });
    }
  });

  return languagesList;
}

export function getFormattedDomainExperienceForAPI(list: TDomainExperienceFormData[]): { domain_exp: string } {
  const formattedDomains: { domain_exp: string } = { domain_exp: '' };

  list.forEach((domain) => {
    formattedDomains.domain_exp += `${domain.domain} - ${domain.experience};`;
  });

  return formattedDomains;
}

export function parseFormattedDomainExperience(domains: string): TDomainExperienceFormData[] {
  if (!domains) return [];
  const domainsList: TDomainExperienceFormData[] = [];

  const domainEntries = domains.split(';').filter(Boolean);

  domainEntries.forEach((entry) => {
    const [domain, experience] = entry.split(' - ');
    if (domain && experience) {
      domainsList.push({ domain, experience });
    }
  });

  return domainsList;
}

export function getFormattedExperiencesForAPI(
  list: {
    companyName: string;
    role: string;
    startDate: Date;
    endDate?: Date | null;
    workingCurrently: boolean;
  }[]
): { [key: string]: string | boolean | null } {
  const formattedExperiences: { [key: string]: string | boolean | null } = {};

  list.forEach((experience, index) => {
    const expIndex = index + 1; // Start index from 1 for formatting
    formattedExperiences[`experience_${expIndex}_compname`] = experience.companyName;
    formattedExperiences[`experience_${expIndex}_role`] = experience.role;
    formattedExperiences[`experience_${expIndex}_start_date`] = format(experience.startDate, 'yyyy-MM-dd');
    formattedExperiences[`experience_${expIndex}_end_date`] = experience.endDate
      ? format(experience.endDate, 'yyyy-MM-dd')
      : null;
    formattedExperiences[`experience_${expIndex}_is_current`] = experience.workingCurrently;
  });

  return formattedExperiences;
}

export function getExperiencesFromAPI(formatted: {
  [key: string]: string | null | boolean | undefined;
}): { companyName: string; role: string; startDate: Date; endDate: Date | null; workingCurrently: boolean }[] {
  const experiences: {
    companyName: string;
    role: string;
    startDate: Date;
    endDate: Date | null;
    workingCurrently: boolean;
  }[] = [];

  for (let i = 1; i <= 3; i++) {
    const companyName = formatted[`experience_${i}_compname`] as string;
    const role = formatted[`experience_${i}_role`] as string;
    const startDate = formatted[`experience_${i}_start_date`]
      ? new Date((formatted[`experience_${i}_start_date`] as string) || '')
      : null;
    const endDate = formatted[`experience_${i}_end_date`]
      ? new Date((formatted[`experience_${i}_end_date`] as string) || '')
      : null;
    const workingCurrently = formatted[`experience_${i}_is_current`] as boolean;
    // Check if companyName is defined to avoid pushing empty objects
    if (companyName && role && startDate && (endDate || workingCurrently)) {
      experiences.push({
        companyName,
        role,
        startDate,
        endDate,
        workingCurrently,
      });
    }
  }

  return experiences;
}

export const stringToDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
};
