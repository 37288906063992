import * as React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export const inputVariants = cva(
  'inline-flex h-12 w-full shrink grow basis-0 items-center justify-start gap-4 rounded-lg border bg-white p-4 px-4 py-3 text-base font-medium leading-5 text-primary-dark-100 placeholder-primary-dark-60 shadow-xs disabled:cursor-not-allowed disabled:border-primary-dark-20 disabled:bg-primary-dark-5 disabled:text-primary-dark-60',
  {
    variants: {
      variant: {
        default:
          'border-primary-dark-20 data-[state=open]:border-primary-blue-40 data-[state=open]:outline-none data-[state=open]:ring-4 data-[state=open]:ring-primary-blue-10 focus-visible:border-primary-blue-40 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-blue-10',
        error:
          'border-primary-alert-100 data-[state=open]:bg-primary-white-100 data-[state=open]:outline-none data-[state=open]:ring-4 data-[state=open]:ring-primary-alert-10 focus:bg-primary-white-100 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-alert-10 disabled:border-primary-alert-100 disabled:bg-primary-alert-5',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  error?: boolean;
  prefix?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, error, prefix, ...props }, ref) => {
  return (
    <div className="relative flex flex-row items-center">
      {prefix && (
        <div className="inline-flex h-12 items-center justify-start gap-4 rounded-bl-lg rounded-tl-lg border-b border-l border-t border-primary-dark-20 bg-primary-white-100 py-3 pl-4 pr-3 shadow-xs">
          <div className="text-base font-medium leading-normal text-primary-dark-60">{prefix}</div>
        </div>
      )}
      <input
        type={type}
        className={cn(
          inputVariants({ variant: error ? 'error' : 'default' }),
          prefix ? 'rounded-bl-none rounded-tl-none' : '',
          className
        )}
        ref={ref}
        {...props}
      />
    </div>
  );
});
Input.displayName = 'Input';

export { Input };
