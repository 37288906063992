import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const separatorVariants = cva('h-[1px] bg-primary-dark-10', {
  variants: {
    size: {
      sm: 'my-6',
      md: 'my-10',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

interface SeparatorProps extends React.HTMLAttributes<HTMLHRElement>, VariantProps<typeof separatorVariants> {}

const Separator: React.FC<SeparatorProps> = ({ className, size }) => {
  return <div className={cn(separatorVariants({ size, className }))} />;
};

export default Separator;
