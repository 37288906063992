import { useCallback } from 'react';
import { useLocation, useNavigate } from '@tanstack/react-router';

const getStepFromRoute = (stepString: string) => {
  const match = stepString.match(/step(\d+)/);

  // If a match is found, parse it as a number
  if (match && match[1]) {
    return parseInt(match[1], 10);
  }

  // If no match is found, return a default value (e.g., 0 or -1)
  return 0;
};

function useStepper() {
  const currentRoute = useLocation({ select: (location) => location.pathname });
  const navigate = useNavigate();

  const getCurrentStep = useCallback(() => getStepFromRoute(currentRoute), [currentRoute]);

  const currentStep = getCurrentStep();

  // Todo add proper logic
  const goToNextStep = () => {
    const nextStep = currentStep + 1;
    navigate({ to: `/candidate/onboarding/step${nextStep}` });
  };

  const goToPreviousStep = () => {
    const previousStep = currentStep - 1;
    navigate({ to: `/candidate/onboarding/step${previousStep}` });
  };

  const goToStep = (step: number) => {
    navigate({ to: `/candidate/onboarding/step${step}` });
  };

  return { currentStep, goToNextStep, goToPreviousStep, goToStep };
}

export default useStepper;
