import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';

import MatchPercentLabel from '@/components/tags/match-percent-label';

import { useGetCurrentCandidate } from '@/hooks/candidate';

import { TCandidateJobPost } from '@/services/candidate';

import { getArrayFromString } from '@/utils/format';
import { getMatchingItemsFromTwoArrays } from '@/utils/string';

import { CultureMatch } from './culture-breakdown';
import { PersonalityMatch } from './personality-breakdown';

export function RoleCharts({ role }: { role: TCandidateJobPost }) {
  const { data: candidate, isLoading: isCandidateLoading } = useGetCurrentCandidate({});

  if (isCandidateLoading) return <div>Loading...</div>;

  const candidateSkills = getArrayFromString(candidate?.skills as string);
  const roleSkills = getArrayFromString(role?.skills as string);

  const matchingSkills = getMatchingItemsFromTwoArrays(candidateSkills, roleSkills);

  const candidateValues = getArrayFromString(candidate?.candidate_values as string);
  const roleValues = getArrayFromString(role?.business.company_values as string);

  const matchingValues = getMatchingItemsFromTwoArrays(candidateValues, roleValues);

  return (
    <Card className="w-full bg-primary-white-100 p-8 shadow-md">
      <div className="flex flex-col space-y-6">
        <h1 className="text-xl font-semibold leading-10 text-primary-dark-100 xl:text-xl 2xl:text-2xl 3xl:text-[32px]">
          Matching results
        </h1>

        <div className="flex flex-col space-y-2">
          <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">Match score</div>
          <div className="flex flex-wrap space-x-2">
            <MatchPercentLabel
              hideLabel
              percent={80}
            />
          </div>
        </div>
        {matchingSkills.length > 0 && (
          <div className="flex flex-col space-y-2">
            <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">Matching skills</div>
            <div className="flex flex-wrap space-x-2">
              {matchingSkills.map((skill) => (
                <Badge
                  hideDot
                  key={skill}
                  variant={'success'}
                >
                  {skill}
                </Badge>
              ))}
            </div>
          </div>
        )}
        {matchingValues.length > 0 && (
          <div className="flex flex-col space-y-2">
            <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">Matching values</div>
            <div className="flex flex-wrap space-x-2">
              {matchingValues.map((value) => (
                <Badge
                  hideDot
                  key={value}
                  variant={'success'}
                >
                  {value}
                </Badge>
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col space-y-2">
          <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">Desired personality</div>
          <div className="w-full rounded-xl border border-primary-dark-10 p-8">
            <PersonalityMatch />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">Team culture</div>
          <div className="w-full rounded-xl border border-primary-dark-10 p-8">
            <CultureMatch role={role} />
          </div>
        </div>
      </div>
    </Card>
  );
}
