import { UilAngleRight } from '@iconscout/react-unicons';
import { Link } from '@tanstack/react-router';

import Avatar from '@/components/ui/avatar';

import { useGetCurrentUser } from '@/hooks/user';

import { getFullName } from '@/utils/string';

import { Skeleton } from '../ui/skeleton';

const ProfileNavLink = () => {
  const { data: user, isLoading: isUserLoading } = useGetCurrentUser({});

  if (isUserLoading) {
    return <Skeleton className="h-[60px]" />;
  }

  return (
    <Link
      className="flex cursor-pointer items-center justify-between gap-4 rounded-lg px-4 py-3 hover:bg-primary-dark-5"
      to="/candidate/settings"
      activeProps={{
        className: 'bg-primary-dark-5',
      }}
    >
      <div className="flex items-center justify-between gap-2">
        <Avatar
          src={user?.image_url}
          size="sm"
        />
        <div className="max-w-[8.5rem] 3xl:max-w-[10.5rem]">
          <p className="truncate text-sm font-semibold">{getFullName(user)}</p>
          <p className="truncate text-xs text-primary-dark-40">{user?.email}</p>
        </div>
      </div>
      <UilAngleRight className="min-h-6 min-w-6 default-icon" />
    </Link>
  );
};

export default ProfileNavLink;
