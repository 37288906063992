import { CultureName, PersonalityArchetypeCode } from '@/services/candidate';

export const CANDIDATE_OPEN_TO_WORK = {
  YES: '1',
  NO: '0',
};

export const PERSONALITY_CODE_TO_EMOJI_MAP: Record<PersonalityArchetypeCode, string> = {
  X: '🦁',
  H: '🐶',
  O: '🐬',
  A: '🐙',
  E: '🐢',
  C: '🐜',
};

export const CULTURE_TO_EMOJI_MAP: Record<CultureName, string> = {
  Clan: '🐺',
  Adhocracy: '🦄',
  Market: '🦅',
  Hierarchy: '🐝',
};

export const getRandomPersonalityTestInfo = () => {
  const personalityTestInfo = {
    H: Math.floor(Math.random() * 9),
    E: Math.floor(Math.random() * 9),
    X: Math.floor(Math.random() * 9),
    C: Math.floor(Math.random() * 9),
    A: Math.floor(Math.random() * 9),
    O: Math.floor(Math.random() * 9),
  };
  return personalityTestInfo;
};
