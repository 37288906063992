import { memo } from 'react';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import FullPageLoader from '@/components/full-page-loader';
import MobileNotSupported from '@/components/mobile-not-supported';

import { getCurrentUser, USER_ENDPOINTS } from '@/services/user';

import PostHogUserIdentifier from '@/lib/posthog/user-identifier';
import { SentryObserver } from '@/lib/sentry/observer';

export const Route = createFileRoute('/_authenticated')({
  component: memo(AuthenticatedLayout),
  beforeLoad: async ({ location, context }) => {
    const { isAuthenticated } = context.auth;
    const queryClient = context.queryClient;

    if (!isAuthenticated) {
      throw redirect({
        to: '/login',
        search: {
          // preserve original intended route
          redirect: location.href,
        },
      });
    }

    try {
      await queryClient.fetchQuery({
        queryFn: getCurrentUser,
        queryKey: [USER_ENDPOINTS.GET_CURRENT_USER],
        staleTime: Infinity,
      });
    } catch (err) {
      throw redirect({
        to: '/error-critical',
        search: {
          // preserve original intended route
          redirect: location.href,
        },
      });
    }
  },
  pendingComponent: FullPageLoader,
});

function AuthenticatedLayout() {
  return (
    <>
      <PostHogUserIdentifier />
      <SentryObserver />
      <MobileNotSupported />
      <div className="hidden sm:block">
        <Outlet />
      </div>
    </>
  );
}
