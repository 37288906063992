import { forwardRef, useEffect } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';

import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select';
import { Textarea } from '@/components/ui/textarea';

import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';
import useStepper from '@/hooks/useStepper';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getArrayFromString, getFormattedDataForAPI } from '@/utils/format';

import { VALUES_GROUP } from '@/constants/values-map';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/_steps/step7')({
  component: forwardRef(Step7),
});

type FormValues = {
  bio: string;
  skills: string[];
  candidate_values: string[];
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: Record<keyof FormValues, { type: string; message: string }> = {} as Record<
    keyof FormValues,
    { type: string; message: string }
  >;

  if (!values.skills || values.skills.length === 0) {
    errors.skills = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.bio || values.bio.length === 0) {
    errors.bio = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (values.bio.length > 1000) {
    errors.bio = {
      type: 'length',
      message: 'Your bio can not be longer than 1000 characters.',
    };
  }
  if (!values.candidate_values || values.candidate_values.length === 0) {
    errors.candidate_values = {
      type: 'required',
      message: 'This is required.',
    };
  }

  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};

function Step7() {
  const { data } = useGetCurrentCandidateSuspense({});
  const { goToNextStep } = useStepper();

  const form = useForm<FormValues>({
    defaultValues: {
      bio: '',
      skills: [],
      candidate_values: [],
    },
    resolver,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = form;

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: { onSuccess: goToNextStep },
  });

  const { data: softSkillsList, isLoading: isSoftSkillLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.SOFT_SKILLS,
      },
    },
  });

  const { data: candidateValuesList, isLoading: isCandidateValueLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.CANDIDATE_VALUES,
      },
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateCandidate({ body: { ...getFormattedDataForAPI(data) } });
  };

  useEffect(() => {
    if (data) {
      const initialValues: FormValues = {
        bio: data.bio || '',
        skills: getArrayFromString(data.skills) || [],
        candidate_values: getArrayFromString(data.candidate_values) || [],
      };
      reset(initialValues);
    }
  }, [data, reset]);

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="bio"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Bio (Max 1000 characters)</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter description here"
                  {...field}
                  error={!!errors?.bio?.message}
                  rows={4}
                  maxLength={1000}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="skills"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Soft skills (Max 10)</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  placeholder="Start typing or select"
                  options={
                    softSkillsList?.items
                      .map((s) => s.description)
                      .filter((s): s is string => s !== undefined && s !== null) || []
                  }
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.skills?.message}
                  enableSearch
                  isLoading={isSoftSkillLoading}
                  maxSelections={10}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="candidate_values"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Values (Max 10)</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  placeholder="Start typing or select"
                  options={
                    candidateValuesList?.items
                      .map((s) => s.description)
                      .filter((s): s is string => s !== undefined && s !== null) || []
                  }
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.candidate_values?.message}
                  enableSearch
                  isLoading={isCandidateValueLoading}
                  maxSelections={10}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <OnboardingFooter
        isLoading={isUpdatingCandidate}
        handleNext={handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
}
