import React from 'react';

import { Card, CardContent, CardHeader } from '@/components/ui/card';

import { CultureChart } from '@/components/quiz/charts/culture-chart';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { CultureName, TCandidateJobPost } from '@/services/candidate';

import { parseCandidateCulture, parseCultureDescription } from '@/utils/string';

import { CULTURE_TO_EMOJI_MAP } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

import { ChartLabels } from './labels';

interface IProps {
  cultureName: CultureName;
  thrust: string;
  means: string;
  ends: string;
  showSecondLabel?: boolean;
}

const CultureBreakdownCard: React.FC<IProps> = ({ cultureName, thrust, means, ends, showSecondLabel }) => {
  return (
    <Card className="mb-8 flex space-x-8 border-none p-0">
      <CardHeader className="flex flex-row items-center justify-between">
        <span className="text-7xl 3xl:text-8xl">{CULTURE_TO_EMOJI_MAP[cultureName]}</span>
      </CardHeader>
      <CardContent className="flex grow justify-between">
        <div className="grid max-w-[37rem] grid-cols-1 space-y-2">
          <div className="grid grid-cols-[5.25rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Culture:</h3>
            <span>{cultureName}</span>
          </div>
          <div className="grid grid-cols-[5.25rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Thrust:</h3>
            <span>{thrust}</span>
          </div>
          <div className="grid grid-cols-[5.25rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Means:</h3>
            <span>{means}</span>
          </div>
          <div className="grid grid-cols-[5.25rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Ends:</h3>
            <span>{ends}</span>
          </div>
        </div>
        <ChartLabels showSecondLabel={showSecondLabel} />
      </CardContent>
    </Card>
  );
};

type TCultureMatchProps = {
  role: TCandidateJobPost;
};

export const CultureMatch: React.FC<TCultureMatchProps> = ({ role }) => {
  const { data: candidate, isLoading: isCandidateLoading } = useGetCurrentCandidate({});

  const { data: cultureValueMap, isLoading: isCultureFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.CULTURE_FACTORS,
      },
    },
  });

  if (isCandidateLoading || isCultureFactorLoading) return <div>Loading...</div>;

  const culture = parseCandidateCulture(candidate?.culture as string);
  const highestRankCulture = culture.reduce((highest, current) => (current.rank < highest.rank ? current : highest));

  const candidateCultureFactor = cultureValueMap?.items?.find(
    (item) => parseCultureDescription(item.description as string).name === (highestRankCulture.name as CultureName)
  )?.description as string;

  const candidateCulture = parseCultureDescription(candidateCultureFactor);

  const cultureFactors = cultureValueMap?.items.map((item) => {
    if (item.description && item.code) {
      const desc = { ...parseCultureDescription(item.description), code: item.code };

      return desc;
    } else
      return {
        code: '',
        name: 'string',
        measures: 'string',
        means: 'string',
        ends: 'string',
        thrust: 'string',
        extras: [''],
      };
  });

  const roleCulture = parseCandidateCulture(role.culture_profile as string);

  if (!cultureFactors) return <span>Cannot render graph. Culture factors not found</span>;

  return (
    <div>
      <CultureBreakdownCard
        cultureName={candidateCulture.name as CultureName}
        thrust={candidateCulture.thrust}
        means={candidateCulture.means}
        ends={candidateCulture.ends}
        showSecondLabel={!!roleCulture.length}
      />
      <CultureChart
        data={culture}
        data2={roleCulture}
        factors={cultureFactors}
      />
    </div>
  );
};
