import { UilCalendarAlt, UilSuitcaseAlt, UilUsdCircle } from '@iconscout/react-unicons';
import { createFileRoute } from '@tanstack/react-router';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Tag } from '@/components/ui/tag';

import BusinessAvatarWithLabel from '@/components/business-avatar-with-label';
import NoData from '@/components/no-data';

import { useGetCurrentCandidateHiredMatches } from '@/hooks/candidate';

import { REMOTE_WORK_OPTIONS } from '@/constants/post';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/roles/hired')({
  component: Hired,
});

function Hired() {
  const { data, isPending } = useGetCurrentCandidateHiredMatches({
    params: {
      query: {
        limit: '50',
        offset: '0',
      },
    },
    reactQueryOptions: {
      queryKey: ['hired'],
    },
  });

  if (data?.items.length === 0) {
    return (
      <NoData
        title="You haven’t been hired yet"
        description={'You haven’t been hired for any roles yet.\nKeep applying, one is right around the corner!'}
      />
    );
  }

  if (isPending)
    return (
      <div className="space-y-6">
        {new Array(3).fill(null).map((_, index) => (
          <Skeleton
            key={index}
            className="h-[14.09375rem] 3xl:h-[12rem]"
          />
        ))}
      </div>
    );

  return (
    <div className="space-y-4">
      {data?.items?.map((role) => (
        <Card
          key={role.id}
          className="flex flex-col gap-y-6 rounded-2xl px-8 py-6"
        >
          <div className="inline-flex justify-between">
            <BusinessAvatarWithLabel
              src={role?.business.logo_url}
              name={role?.business?.name}
              location={role?.location}
              avatarSize={'xl'}
            />
            <span>
              <Badge variant={'success'}>Active</Badge>
            </span>
          </div>
          <div className="grid grid-cols-[2fr_3fr_1fr] gap-10">
            <div className="space-y-2">
              <div className="inline-flex items-center gap-2">
                <UilSuitcaseAlt className="!text-primary-dark-40 default-icon-sm" />
                <span className="font-semibold text-primary-dark-40">Role</span>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <span className="font-semibold">{role.title}</span>
                <Tag variant={'primary'}>{role.remote_work === REMOTE_WORK_OPTIONS.YES ? 'Remote' : 'On-site'}</Tag>
                <Tag variant={'primary'}>{role.employment_type}</Tag>
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="flex flex-col space-y-2">
                <div className="inline-flex items-center gap-2">
                  <UilUsdCircle className="!text-primary-dark-40 default-icon-sm" />
                  <span className="font-semibold text-primary-dark-40">Agreed rate(AUD)</span>
                </div>
                <span>-</span>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="inline-flex items-center gap-2">
                  <UilCalendarAlt className="!text-primary-dark-40 default-icon-sm" />
                  <span className="font-semibold text-primary-dark-40">Start Date</span>
                </div>
                <span>-</span>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="inline-flex items-center gap-2">
                  <UilCalendarAlt className="!text-primary-dark-40 default-icon-sm" />
                  <span className="font-semibold text-primary-dark-40">Est. End date</span>
                </div>
                <span>-</span>
              </div>
            </div>

            <div className="flex flex-col items-end justify-end">
              <Button disabled>View Contract</Button>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}
