import { Link, ToOptions } from '@tanstack/react-router';

export interface INavLinkProps {
  icon: React.ReactNode;
  href: ToOptions['to'];
  label: string;
  exact?: boolean;
}

const NavLink: React.FC<INavLinkProps> = ({ icon, href, label, exact = true }) => {
  return (
    <Link
      to={href}
      className="flex cursor-pointer items-center gap-2 rounded-lg px-4 py-2.5 font-semibold transition-colors hover:bg-primary-dark-5"
      activeProps={{
        className: 'bg-primary-dark-5 text-primary-dark-100',
      }}
      inactiveProps={{
        className: 'text-primary-dark-60',
      }}
      activeOptions={{
        exact,
      }}
    >
      <span>{icon}</span>
      <span className="mt-0.5">{label}</span>
    </Link>
  );
};

export default NavLink;
