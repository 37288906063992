import client, { type TRequestOptions } from '@/lib/api';

import { USER_ENDPOINTS } from './user.endpoints';
import { TGetCurrentUserRequest, TUPdateCurrentUserPasswordRequest, TUpdateCurrentUserRequest } from './user.types';

const { GET_CURRENT_USER } = USER_ENDPOINTS;
export const getCurrentUser = async ({ signal }: TRequestOptions<TGetCurrentUserRequest>) => {
  const { data } = await client.GET(GET_CURRENT_USER, {
    signal,
  });

  return data?.data;
};

export const updateCurrentUsersPassword = async ({
  signal,
  body,
}: TRequestOptions<TUPdateCurrentUserPasswordRequest>) => {
  return await client.PUT(USER_ENDPOINTS.UPDATE_PASSWORD, { signal, body });
};

export const updateCurrentUser = async ({ signal, body }: TRequestOptions<TUpdateCurrentUserRequest>) => {
  return await client.PUT(USER_ENDPOINTS.UPDATE_CURRENT_USER, { signal, body });
};
