import Avatar, { AvatarProps } from '@/components/ui/avatar';

import LocationTag from '@/components/tags/location-tag';

interface IProps {
  src?: string | null;
  name?: string | null;
  location?: string | null;
  id?: string;
  avatarSize?: AvatarProps['size'];
}

const BusinessAvatarWithLabel: React.FC<IProps> = ({ src, name, location, avatarSize = 'xl' }) => {
  return (
    <div className="flex items-center gap-2">
      <Avatar
        fallbackType="company"
        src={src}
        size={avatarSize}
      />
      <div className="space-y-1">
        <p className="font-semibold">{name}</p>
        {location && <LocationTag location={location} />}
      </div>
    </div>
  );
};

export default BusinessAvatarWithLabel;
