import { ToOptions, useMatchRoute, useNavigate } from '@tanstack/react-router';

import { Tabs, TabsList, TabsTrigger, TTabTriggerVariantProps } from '@/components/ui/tabs';

export interface ITabNavItem {
  label: string;
  href: ToOptions['to'];
  fuzzyMatch?: boolean;
}

interface IProps extends TTabTriggerVariantProps {
  navItems: ITabNavItem[];
  className?: string;
  triggerClassName?: string;
}

const TabNav: React.FC<IProps> = ({ navItems, variant, triggerClassName, className }) => {
  const matchRoute = useMatchRoute();
  const navigate = useNavigate();

  const activeTab = navItems.find((item) => matchRoute({ to: item.href, fuzzy: item.fuzzyMatch }));

  return (
    <Tabs
      value={activeTab?.href}
      onValueChange={(value: string) => {
        navigate({
          to: value,
          resetScroll: false,
        });
      }}
      className={className}
    >
      <TabsList variant={variant}>
        {navItems.map((item) => (
          <TabsTrigger
            value={item.href!}
            key={item.label}
            variant={variant}
            className={triggerClassName}
          >
            {item.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default TabNav;
