import { Button } from '@/components/ui/button';
import { Card, CardFooter } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import SettingContainer from './setting-container';

const DeactivateAccountCard = () => {
  return (
    <SettingContainer>
      <Heading
        title="Danger zone"
        subtitle="Deactivating your account."
      />
      <Card>
        <Heading
          title="Deactivate account"
          subtitle="Deactivating your account will remove your profile from our system. Businesses will no longer be able view your profile."
        />
        <CardFooter>
          <Button variant="destructive">Deactivate account</Button>
        </CardFooter>
      </Card>
    </SettingContainer>
  );
};

export default DeactivateAccountCard;
