import { UilHeadphonesAlt } from '@iconscout/react-unicons';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from '@/components/ui/button';
import Separator from '@/components/ui/separator';
import { toast } from '@/components/ui/toaster';

import { useApplyForARole } from '@/hooks/post';

import { CANDIDATE_ENDPOINTS, TCandidateJobPost, TCandidateMatchStatus } from '@/services/candidate';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

export function CTA({
  candidateStatus,
  post,
}: {
  candidateStatus?: TCandidateMatchStatus | null;
  post: TCandidateJobPost;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync: applyForARole, isPending: isApplyLoading } = useApplyForARole({
    params: {
      path: {
        id: post.id.toString(),
      },
    },
    reactQueryOptions: {
      onSuccess: () => {
        toast.success('Application submitted successfully');
        queryClient.invalidateQueries({
          queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_SINGLE_JOB_POST],
        });
      },
    },
  });

  const handleApply = async () => {
    await applyForARole({ params: { path: { id: post.id.toString() } } });
  };

  if (post.status === 1 && candidateStatus === null) {
    return (
      <>
        <Separator />
        <Button
          size="md"
          onClick={handleApply}
          loading={isApplyLoading}
        >
          Apply
        </Button>
      </>
    );
  }
  if (candidateStatus === ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE) {
    return (
      <div className="flex w-full flex-col space-y-4 2xl:flex-row 2xl:space-x-4 2xl:space-y-0">
        <Button
          size={'lg'}
          variant="tertiary"
          className="flex-1"
        >
          Decline
        </Button>
        <Button
          className="flex-1"
          size={'lg'}
        >
          Book a slot
        </Button>
      </div>
    );
  }
  if (candidateStatus === ROLE_CANDIDATE_STATUS.INTERVIEWING) {
    // TODO: Fix this after meeting feature is refactored
    const startTime = new Date();
    const endTime = new Date();
    return (
      <div className="inline-flex flex-col items-start justify-start gap-8 rounded-lg border border-[#a0e2c3] bg-[#f3fbf8] p-4">
        <div className="inline-flex items-start justify-start gap-4 self-stretch">
          <div className="flex h-12 w-12 items-center justify-center gap-2.5 rounded-lg bg-[#12b76a] px-1 py-0.5">
            <UilHeadphonesAlt className="text-primary-white-100" />
          </div>
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1.5">
            <div className="self-stretch text-base font-semibold leading-normal text-[#0c2149]">
              {post.title} • {post.business.name}
            </div>
            <div className="text-xs font-medium leading-[18px] text-[#6d7a92]">
              {formatDateRange(startTime, endTime)}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col space-y-4 2xl:flex-row 2xl:space-x-4 2xl:space-y-0">
          <Button
            size={'lg'}
            variant="tertiary"
            className="flex-1"
          >
            Need help?
          </Button>
          <Button
            className="flex-1"
            size={'lg'}
          >
            Join meeting
          </Button>
        </div>
      </div>
    );
  }
  if (candidateStatus === ROLE_CANDIDATE_STATUS.HIRED) {
    return <Button size="lg">Download contract</Button>;
  }
  return null;
}
function formatDateRange(startDate: Date, endDate: Date): string {
  const dayFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long' });
  const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  const day = dayFormatter.format(startDate);
  const date = dateFormatter.format(startDate);
  const startTime = timeFormatter.format(startDate);
  const endTime = timeFormatter.format(endDate);

  return `${day}, ${date} • ${startTime} - ${endTime}`;
}
