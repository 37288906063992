import { createFileRoute } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import useAuth from '@/hooks/useAuth';

export const Route = createFileRoute('/_authenticated/business/dashboard')({
  component: BusinessLayout,
});

function BusinessLayout() {
  const { logout } = useAuth();
  return (
    <>
      <Button onClick={logout}>Sign out</Button>
    </>
  );
}
