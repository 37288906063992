import * as React from 'react';
import { UilUser } from '@iconscout/react-unicons';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

import CompanyAvatarImg from '@/assets/avatar-company.png';

const AvatarRoot = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full border border-primary-dark-10 bg-primary-blue-10',
      className
    )}
    {...props}
  />
));
AvatarRoot.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('aspect-square h-full w-full', className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'flex h-full w-full items-center justify-center rounded-full bg-slate-100 dark:bg-slate-800',
      className
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const avatarVariants = cva('relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full', {
  variants: {
    variant: {
      default: '',
      bordered: 'border-4 border-primary-white-100 shadow-sm',
    },
    size: {
      xs: 'h-6 w-6',
      sm: 'h-8 w-8',
      md: 'h-10 w-10',
      lg: 'h-12 w-12',
      xl: 'h-[3.5rem] w-[3.5rem]',
      '2xl': 'h-24 w-24',
      '3xl': 'h-40 w-40',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'md',
  },
});

const fallbackIconContainerVariants: typeof avatarVariants = cva(
  'flex h-full w-full items-center justify-center rounded bg-primary-blue-5',
  {
    variants: {
      size: {
        xs: 'p-1',
        sm: 'p-1.5',
        md: 'p-2.5',
        lg: 'p-3',
        xl: 'p-3.5',
        '2xl': 'p-4',
        '3xl': 'p-10',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

export interface AvatarProps extends VariantProps<typeof avatarVariants> {
  src?: string | null;
  className?: string;
  fallbackType?: 'user' | 'company';
}

const Avatar: React.FC<AvatarProps> = ({ src, className, fallbackType = 'user', size, variant }) => {
  return (
    <AvatarRoot className={cn(avatarVariants({ variant, size, className }))}>
      <AvatarImage
        src={src || undefined}
        alt="Avatar"
        className="object-cover"
      />
      <AvatarFallback>
        {fallbackType === 'user' ? (
          <span className={cn(fallbackIconContainerVariants({ size }))}>
            <UilUser className="h-full w-full text-primary-blue-100" />
          </span>
        ) : (
          <img
            src={CompanyAvatarImg}
            alt="Avatar"
            className="aspect-square h-full w-full object-cover"
          />
        )}
      </AvatarFallback>
    </AvatarRoot>
  );
};

export default Avatar;
