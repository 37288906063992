import Spinner from './ui/spinner';

const FullPageLoader = () => {
  return (
    <div className="flex h-[100dvh] w-screen items-center justify-center">
      <Spinner size="xl" />
    </div>
  );
};

export default FullPageLoader;
