import { cn } from '@/lib/utils';

import FeaturedIcon from './icons/featured-icon';

interface INoDataProps {
  title: string;
  description: string;
  className?: string;
  isSearchResults?: boolean;
}

const NoData: React.FC<INoDataProps> = ({ className, isSearchResults, ...props }) => {
  const title = isSearchResults ? 'No search results' : props.title;
  const description = isSearchResults
    ? 'We couldn’t find any results for your search.\nPlease try again.'
    : props.description;

  return (
    <div className={cn('flex min-h-[65vh] items-center justify-center text-center', className)}>
      <div className="flex flex-col items-center space-y-4">
        <FeaturedIcon
          variant={isSearchResults ? 'search' : 'warning'}
          size="lg"
        />
        <div className="space-y-2">
          <h4 className="text-base font-semibold">{title}</h4>
          <div className="text-xs text-primary-dark-60">
            {description.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoData;
