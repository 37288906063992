import { memo, useEffect, useState } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import Avatar from '@/components/ui/avatar';

import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

import { useGetCurrentUser } from '@/hooks/user';

import { getFullName } from '@/utils/string';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings')({
  component: memo(SettingsLayout),
});

const Banner = () => {
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalNode(document.getElementById('dashboard-container'));
  }, []);

  if (!portalNode) return null;

  return createPortal(
    <div className="absolute left-0 top-0 z-0 h-[200px] w-full bg-[url('/profile-settings-banner.svg')]"></div>,
    portalNode
  );
};

const navItems: ITabNavItem[] = [
  {
    label: 'My details',
    href: '/candidate/settings',
  },
  {
    label: 'Profile',
    href: '/candidate/settings/profile',
    fuzzyMatch: true,
  },
  {
    label: 'Password',
    href: '/candidate/settings/password',
  },
  {
    label: 'FAQs',
    href: '/candidate/settings/faqs',
  },
];

function SettingsLayout() {
  const { data: user } = useGetCurrentUser({});

  return (
    <>
      <Banner />
      <main className="relative z-10">
        <div className="mt-32 flex items-center gap-x-10">
          <Avatar
            src={user?.image_url}
            size="3xl"
            variant={'bordered'}
          />
          <h1 className="text-3xl font-semibold">{getFullName(user)}</h1>
        </div>
        <nav className="py-12">
          <TabNav navItems={navItems} />
        </nav>
        <Outlet />
      </main>
    </>
  );
}
