import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/login')({
  component: Login,
  beforeLoad: ({ context }) => {
    const { isAuthenticated, loginWithRedirect } = context.auth;

    if (isAuthenticated) {
      throw redirect({ to: '/' });
    } else {
      throw loginWithRedirect({
        //return to original intended route after login
        appState: { returnTo: window.location.href },
      });
    }
  },
});

function Login() {
  return <div>Hello /login!</div>;
}
