import { createFileRoute, redirect } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import useAuth from '@/hooks/useAuth';

import { USER_TYPE } from '@/services/user';

export const Route = createFileRoute('/_authenticated/')({
  component: Home,
  beforeLoad: ({ context }) => {
    const { user } = context.auth;

    //check role and redirect
    if (user?.app_user_type === USER_TYPE.CANDIDATE) {
      throw redirect({ to: '/candidate' });
    }

    if (user?.app_user_type === USER_TYPE.BUSINESS) {
      throw redirect({ to: '/business/dashboard' });
    }
  },
});

function Home() {
  const { logout } = useAuth();

  return (
    <div className="mx-auto flex h-screen max-w-screen-md flex-col items-center justify-center gap-3">
      {" You've signed in with an supported account."}
      <Button
        variant="destructive"
        onClick={logout}
      >
        Sign out
      </Button>
    </div>
  );
}
