import {
  UilCheckCircle,
  UilExclamationCircle,
  UilExclamationTriangle,
  UilFileAlt,
  UilSearch,
} from '@iconscout/react-unicons';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const innerCircleVariants = cva('flex items-center justify-center rounded-full p-1.5', {
  variants: {
    variant: {
      success: 'bg-primary-success-20',
      warning: 'bg-primary-warning-20',
      document: 'bg-primary-blue-20',
      alert: 'bg-primary-alert-20',
      search: 'bg-primary-blue-20',
    },
    size: {
      sm: 'p-1',
      default: 'p-2.5',
      lg: 'p-3',
    },
  },
  defaultVariants: {
    variant: 'warning',
  },
});

const outerCircleVariants = cva('flex items-center justify-center rounded-full p-1', {
  variants: {
    variant: {
      success: 'bg-primary-success-10',
      warning: 'bg-primary-warning-10',
      document: 'bg-primary-blue-10',
      alert: 'bg-primary-alert-10',
      search: 'bg-primary-blue-10',
    },
  },
  defaultVariants: {
    variant: 'warning',
  },
});

export const iconVariants = cva('', {
  variants: {
    variant: {
      success: 'text-primary-success-120',
      warning: 'text-primary-warning-120',
      document: 'text-primary-blue-120',
      alert: 'text-primary-alert-120',
      search: 'text-primary-blue-120',
    },
    size: {
      sm: 'h-4 w-4',
      default: 'h-5 w-5',
      lg: 'h-8 w-8',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const variantToIconMap: Record<string, (props: VariantProps<typeof iconVariants>) => JSX.Element> = {
  success: ({ size }) => <UilCheckCircle className={cn(iconVariants({ size, variant: 'success' }))} />,
  warning: ({ size }) => <UilExclamationTriangle className={cn(iconVariants({ size, variant: 'warning' }))} />,
  document: ({ size }) => <UilFileAlt className={cn(iconVariants({ size, variant: 'document' }))} />,
  alert: ({ size }) => <UilExclamationCircle className={cn(iconVariants({ size, variant: 'alert' }))} />,
  search: ({ size }) => <UilSearch className={cn(iconVariants({ size, variant: 'search' }))} />,
};

interface IProps extends VariantProps<typeof innerCircleVariants>, VariantProps<typeof iconVariants> {
  className?: string;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | null;
}

const FeaturedIcon: React.FC<IProps> = ({ variant, className, size, Icon }) => {
  if (!variant) return null;
  const DefaultIcon = variant ? variantToIconMap[variant] : null;
  return (
    <div className={cn('inline-block', className)}>
      <div className={cn(outerCircleVariants({ variant, className }))}>
        <div className={cn(innerCircleVariants({ variant, size, className }))}>
          {Icon ? <Icon className={cn(iconVariants({ size, variant }))} /> : DefaultIcon && DefaultIcon({ size })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedIcon;
