import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { useUpdateCurrentUsersPassword } from '@/hooks/user';

import { changePasswordSchema, TChangePasswordFormData } from '@/validation-schemas/change-password-schema';

import FormFooter from './form-footer';
import FormNavigationBlocker from './form-navigation-blocker';

const ChangePasswordForm = () => {
  const form = useForm<TChangePasswordFormData>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = form;

  const { mutateAsync, isPending, isSuccess } = useUpdateCurrentUsersPassword({
    reactQueryOptions: {
      onSuccess: () => {
        reset();
      },
    },
  });

  const onSubmit: SubmitHandler<TChangePasswordFormData> = async (data) => {
    await mutateAsync({
      body: {
        old_password: data.oldPassword,
        new_password: data.newPassword,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form>
        <FormField
          control={control}
          name="oldPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Current password</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="password"
                  placeholder="Current password"
                  error={!!errors?.oldPassword}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New password</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="password"
                  placeholder="New password"
                  error={!!errors?.newPassword}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm password</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="password"
                  placeholder="Re-enter new password"
                  error={!!errors?.confirmPassword}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormNavigationBlocker disabled={isSuccess} />
      </Form>
      <FormFooter
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isPending}
      />
    </FormProvider>
  );
};

export default ChangePasswordForm;
