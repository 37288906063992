import { useQuery } from '@tanstack/react-query';

import { getAllSkillsets, SKILLSET_ENDPOINTS, TAllSkillsetsRequest } from '@/services/skillset';

import { type UseQueryOptions } from '../types';

const { GET_ALL_SKILLSETS } = SKILLSET_ENDPOINTS;

export function useGetAllSkillsets({ reactQueryOptions, params }: UseQueryOptions<TAllSkillsetsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_ALL_SKILLSETS, params?.query?.q],
    queryFn: () =>
      getAllSkillsets({
        params: {
          query: {
            ...params?.query,
            limit: params?.query?.limit || '10',
            offset: params?.query?.offset || '0',
          },
        },
      }),
    staleTime: Infinity,
  });
}
