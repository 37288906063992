import { UilLinkAlt } from '@iconscout/react-unicons';

import Avatar from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

import { TCandidateJobPost } from '@/services/candidate';

import { getArrayFromString } from '@/utils/format';

import LinkedInIcon from '@/assets/linkedin-icon.svg';
import RolesPlaceHolderImg from '@/assets/roles-placeholder.jpeg';

export function MainDetails({ post }: { post: TCandidateJobPost }) {
  return (
    <Card className="w-full bg-primary-white-100 p-8 shadow-md">
      <img
        src={post.business.cover_url || RolesPlaceHolderImg}
        alt="roles placeholder image"
        className="h-80 max-h-80 w-full flex-shrink-0 rounded-lg object-cover lg:max-h-20 xl:max-h-40 2xl:max-h-60 3xl:max-h-80"
      />
      <div className="flex w-full justify-between py-4">
        <div className="flex items-center space-x-4">
          <Avatar
            fallbackType="company"
            src={post.business.logo_url}
            size="lg"
          />
          <p className="text-base font-semibold leading-loose text-primary-dark-100 lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl">
            {post.business?.name}
          </p>
        </div>
        <div className="flex space-x-4 text-sm text-primary-dark-100">
          {post.business.linkedin_url && (
            <a
              href={post.business?.linkedin_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="tertiary"
                size={'icon'}
              >
                <img
                  src={LinkedInIcon}
                  alt="Linkedin Icon"
                />
              </Button>
            </a>
          )}
          {post.business.website && (
            <a
              href={`https://${post.business.website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="tertiary"
                className="flex space-x-2"
              >
                <UilLinkAlt className="h-[18px]" /> <span>Website</span>
              </Button>
            </a>
          )}

          {/* <Button
            variant="tertiary"
            className="flex space-x-2"
            disabled
          >
            <UilExclamationTriangle className="h-[18px]" /> <span>Report</span>
          </Button> */}
        </div>
      </div>
      <div className="flex flex-col space-y-6">
        <h1 className="text-xl font-semibold leading-10 text-primary-dark-100 xl:text-xl 2xl:text-2xl 3xl:text-[32px]">
          {post.title}
        </h1>

        {post?.skills && (
          <div className="flex flex-col space-y-2">
            <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">Skills</div>
            <div className="flex flex-wrap space-x-2">
              {getArrayFromString(post?.skills).map((skill) => (
                <Badge
                  hideDot
                  key={skill}
                >
                  {skill}
                </Badge>
              ))}
            </div>
          </div>
        )}
        {post.business.company_values && (
          <div className="flex flex-col space-y-2">
            <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">Company values</div>
            <div className="flex flex-wrap space-x-2">
              {getArrayFromString(post.business.company_values).map((value) => (
                <Badge
                  hideDot
                  key={value}
                >
                  {value}
                </Badge>
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col space-y-2">
          <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">About the role</div>
          <div>
            <p className="text-sm text-primary-dark-60">{post.description}</p>
          </div>
        </div>
        {/* <div className="flex flex-col space-y-2">
          <div className="flex items-center gap-x-2 text-base font-semibold leading-normal">
            Meet the hiring team
          </div>
          <div>
            <p className="text-sm font-medium leading-tight text-primary-dark-40">hiring team here</p>
          </div>
        </div> */}
      </div>
    </Card>
  );
}
