import { TCandidateCulture } from '@/services/candidate';
import { TCultureDescription, TCultureFactor, TPersonalityDescription } from '@/services/values-map';

import { Nullable } from '@/lib/types';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/constants/table';

export function parsePersonalityDescription(inputString: string): TPersonalityDescription {
  // Initialize the object
  const obj: TPersonalityDescription = {
    name: '',
    facets: [],
    adjectives: {
      positive: [],
      negative: [],
    },
    defaultAdjectives: '',
  };
  if (!inputString) return obj;
  // Remove the surrounding curly braces and split the input string into key-value pairs
  inputString = inputString.slice(1, -1);
  //TODO There is typo when creating the value map. There is no comma before it when saved. It may break anglura app if added now.
  inputString = inputString.replace(' "Adjectives"', ',  "Adjectives"');
  const keyValuePairs = inputString.split(',  ');

  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split(': ').map((item) => item.replace(/"/g, ''));
    if (key === 'Name') {
      obj.name = value;
    } else if (key === 'Facets') {
      obj.facets = value.split(', ').map((facet) => facet.trim());
    } else if (key === 'Adjectives') {
      obj.defaultAdjectives = value;
      const adjectives = value.split(', ');
      adjectives.forEach((adj) => {
        const [positive, negative] = adj.split(' versus ').map((item) => item.trim());
        if (positive) obj.adjectives.positive.push(positive);
        if (negative) obj.adjectives.negative.push(negative);
      });
    }
  });

  return obj;
}

export function parseCultureDescription(inputString: string): TCultureDescription {
  const obj = {
    name: '',
    measures: '',
    thrust: '',
    means: '',
    ends: '',
    extras: [''],
  };
  try {
    const culture: TCultureFactor = JSON.parse(inputString);
    obj.name = culture.name;
    obj.measures = culture.measures;
    obj.thrust = culture.description.thrusts;
    obj.means = culture.description.means;
    obj.ends = culture.description.ends;
    obj.extras = culture.description.extras;
    return obj;
  } catch (error) {
    console.error("Couldn't parse the string", error);
    return obj;
  }
}

export function parseCandidateCulture(jsonString: string): TCandidateCulture[] {
  try {
    const parsedData: TCandidateCulture[] = JSON.parse(jsonString);
    if (!parsedData) return [];
    return parsedData;
  } catch (error) {
    console.error('Error parsing JSON string:', error);
    return [];
  }
}

export function prepareCultureQuizData(obj: TCandidateCulture[]): string {
  try {
    const stringifiedData: string = JSON.stringify(obj);
    return stringifiedData;
  } catch (error) {
    console.error('Error parsing JSON string:', error);
    return '';
  }
}

export function castToStringOrNull(value: string | null | undefined): string | null {
  return value === undefined ? null : value;
}

export const cleanEmptyParams = <T extends Record<string, unknown>>(search: T) => {
  const newSearch = { ...search };
  Object.keys(newSearch).forEach((key) => {
    const value = newSearch[key];
    if (value === undefined || value === '' || (typeof value === 'number' && isNaN(value))) delete newSearch[key];
  });

  if (search.pageIndex === DEFAULT_PAGE_INDEX) delete newSearch.pageIndex;
  if (search.pageSize === DEFAULT_PAGE_SIZE) delete newSearch.pageSize;

  return newSearch;
};

export const getFullName = (user?: { first_name?: Nullable<string>; last_name?: Nullable<string> }): string => {
  return `${user?.first_name || ''} ${user?.last_name || ''}`.trim();
};

export const getMatchingItemsFromTwoArrays = (array1: string[], array2: string[]): string[] => {
  const matchingItems = array1.filter((item) => array2.includes(item));
  return matchingItems;
};

export const truncateString = (str: string, n: number = 10): string => {
  if (str.length <= n) return str;

  return str.slice(0, n - 3) + '...';
};

export function emojiToCountryCode(emoji: string | null) {
  if (!emoji) {
    return null;
  }

  const codePoints = Array.from(emoji, (char) => {
    const codePoint = char.codePointAt(0);
    return codePoint ? codePoint - 127397 : 0; // Fallback to 0 if codePoint is undefined
  });

  return String.fromCharCode(...codePoints); // Convert to string with country code
}
