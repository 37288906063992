import { useNavigate } from '@tanstack/react-router';
import { ColumnDef, PaginationState } from '@tanstack/react-table';

import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { DataTable } from '@/components/ui/data-table';

import LocationTag from '@/components/tags/location-tag';
import MatchPercentLabel from '@/components/tags/match-percent-label';

import {
  TArchivedCandidateMatch,
  TArchivedCandidateMatches,
  TCandidateJobStatus,
  TCandidatePostsFilters,
  TCurrentCandidateAppliedMatch,
  TCurrentCandidateAppliedMatches,
} from '@/services/candidate';

import { sortByToState, stateToSortBy } from '@/utils/table-sort-mapper';

import { MatchStatusTag } from '../tags/match-status-tag';

interface ICandidateTableProps {
  data?: TCurrentCandidateAppliedMatches | TArchivedCandidateMatches;
  isPending: boolean;
  filters: TCandidatePostsFilters;
  setFilters: (filters: TCandidatePostsFilters) => void;
  paginationState: PaginationState;
  isArchived?: boolean; // Remove this if there is more than two instance of this table and add more props
}

export default function CandidateRolesTable({
  data,
  isPending,
  setFilters,
  filters,
  paginationState,
  isArchived,
}: ICandidateTableProps) {
  const navigate = useNavigate();
  const sortingState = sortByToState(filters.sortBy);
  const columnDefs: ColumnDef<TCurrentCandidateAppliedMatch | TArchivedCandidateMatch>[] = [
    {
      accessorKey: 'business.name',
      id: 'business.name',
      header: 'Company',
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-4">
            <Avatar
              fallbackType="company"
              src={row.original?.business?.logo_url}
              size="md"
            />
            <span className="text-sm font-semibold">{row.original?.business?.name}</span>
          </div>
        );
      },
    },
    {
      header: 'Match',
      accessorKey: 'metadata.match_percentage',
      id: 'metadata.match_percentage',
      cell: ({ getValue }) => {
        return (
          <MatchPercentLabel
            percent={getValue<number>()}
            hideLabel
          />
        );
      },
    },
    {
      accessorKey: 'title',
      header: 'Role',
    },
    {
      accessorKey: 'location',
      header: 'Location',
      cell: ({ row }) => {
        return (
          <>
            {row.original.location && (
              <LocationTag
                location={row.original.location}
                useCountryCode
              />
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'day_rate',
      header: 'Rate',
    },
    {
      accessorKey: 'metadata.candidate_status',
      id: 'metadata.candidate_status',
      header: 'Status',
      cell: ({ row }) => {
        return (
          <>
            {row.original?.metadata?.candidate_status && row.original.status && (
              <MatchStatusTag
                candidateMatchStatus={row.original.metadata.candidate_status}
                jobPostStatus={row.original.status as TCandidateJobStatus}
              />
            )}
          </>
        );
      },
    },
    {
      id: 'id',
      cell: (row) => {
        return (
          <Button
            variant="link"
            onClick={() => navigate({ to: `/candidate/role/${row.row.original.id}` })}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columnDefs}
      data={data?.items || []}
      title={isArchived ? 'Archived' : 'Active'}
      isLoading={isPending}
      pagination={{
        state: paginationState,
        options: {
          rowCount: data?.total || 0,
          onPaginationChange: (pagination) => {
            setFilters(typeof pagination === 'function' ? pagination(paginationState) : pagination);
          },
        },
      }}
      search={{
        value: filters.query || '',
        onChange: (value) => {
          setFilters({ ...filters, query: value });
        },
      }}
      noData={{
        title: isArchived ? 'No archived roles' : 'No roles available',
        description: isArchived
          ? 'You currently have no archived roles available.'
          : 'You haven’t applied for any roles yet.\n Start applying now!',
      }}
      sorting={{
        state: sortingState,
        onSortingChange: (updaterOrValue) => {
          const newSortingState = typeof updaterOrValue === 'function' ? updaterOrValue(sortingState) : updaterOrValue;
          return setFilters({ sortBy: stateToSortBy(newSortingState) });
        },
      }}
    />
  );
}
