import { Skeleton } from '@/components/ui/skeleton';

export function LoadingRoleDetail() {
  return (
    <main className="flex space-x-8">
      <Skeleton className="[h-8dvh] min-w-[420px] max-w-[420px]" />
      <Skeleton className="[h-8dvh] flex grow" />
    </main>
  );
}
