import { Auth0ContextInterface } from '@auth0/auth0-react';
import createClient, { ParamsOption, RequestBodyOption, type Middleware } from 'openapi-fetch';

import type { paths } from './v1';

const client = createClient<paths>({ baseUrl: import.meta.env.VITE_API_BASE_URL });

const throwOnError: Middleware = {
  async onResponse({ response }) {
    if (response.status >= 400) {
      const body = response.headers.get('content-type')?.includes('json')
        ? await response.clone().json()
        : await response.clone().text();
      throw new Error(body);
    }
    return undefined;
  },
};

client.use(throwOnError);

export const addAccessTokenInterceptor = (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  logout: Auth0ContextInterface['logout']
) => {
  const addToken: Middleware = {
    async onRequest({ request }) {
      try {
        const token = await getAccessTokenSilently();
        request.headers.set('Authorization', `Bearer ${token}`);
      } catch (err) {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
    },
  };

  client.use(addToken);
};

export type TRequestOptions<T> = ParamsOption<T> &
  RequestBodyOption<T> & {
    signal?: AbortSignal;
  };

export default client;
