import { router } from '@/router';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';

import { Toaster } from '@/components/ui/toaster';

import AuthInject from '@/components/injectable/auth-inject';
import HotjarInject from '@/components/injectable/hotjar-inject';

import useAuth from '@/hooks/useAuth';

import { PHProvider } from '@/lib/posthog/posthog-provider';

import FullPageLoader from './components/full-page-loader';

const queryClient = new QueryClient();

function InternalApp() {
  const auth = useAuth();

  if (auth.isLoading) {
    return <FullPageLoader />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <HotjarInject />
      <ReactQueryDevtools
        initialIsOpen={false}
        buttonPosition="top-left"
      />
      <Toaster />
      <RouterProvider
        router={router}
        context={{ auth, queryClient }}
      />
    </QueryClientProvider>
  );
}

function App() {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        scope: 'openid profile email offline_access',
      }}
      cacheLocation="localstorage" // save tokens in local storage
    >
      <AuthInject />
      <PHProvider>
        <InternalApp />
      </PHProvider>
    </Auth0Provider>
  );
}

export default App;
