import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';

import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { VALUES_GROUP } from '@/constants/values-map';

import {
  additionalLanguageSchema,
  domainExperienceSchema,
  TAdditionalLanguageFormData,
  TDomainExperienceFormData,
} from '@/validation-schemas/coupled-fields.schema';

import { Button } from '../ui/button';
import { Select } from '../ui/select';

interface IAdditionalLanguageProps {
  defaultValues?: TAdditionalLanguageFormData;
  onSubmit: (data: TAdditionalLanguageFormData) => void;
  selectedLanguages?: string[];
}

export const AdditionalLanguageForm: React.FC<IAdditionalLanguageProps> = ({
  defaultValues,
  selectedLanguages,
  onSubmit,
}) => {
  const form = useForm<TAdditionalLanguageFormData>({
    resolver: zodResolver(additionalLanguageSchema),
    defaultValues,
    values: defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = form;

  const { data: languagesList, isLoading: isLanguagelistLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.LANGUAGES,
      },
    },
  });
  const { data: languagesProficiencyList, isLoading: isLanguageProficiencylistLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.ENGLISH_PROFECIENCY,
      },
    },
  });

  const onAdd: SubmitHandler<TAdditionalLanguageFormData> = async (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <FormProvider {...form}>
      <Form>
        <div className="flex items-end gap-x-8 pt-8">
          <FormField
            name="language"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Additional languages</FormLabel>
                <FormControl>
                  <Select
                    className=""
                    options={
                      languagesList?.items
                        .map((s) => s.description)
                        .filter((s): s is string => s !== undefined && s !== null && !selectedLanguages?.includes(s)) ||
                      []
                    }
                    selected={field.value}
                    placeholder="Select Option"
                    onChange={(value) => field.onChange(value)}
                    error={!!errors?.language?.message}
                    isLoading={isLanguagelistLoading}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            name="proficiency"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Competency</FormLabel>
                <FormControl>
                  <Select
                    className=""
                    options={
                      languagesProficiencyList?.items
                        .map((s) => s.description)
                        .filter((s): s is string => s !== undefined && s !== null) || []
                    }
                    selected={field.value}
                    placeholder="Select Option"
                    onChange={(value) => field.onChange(value)}
                    error={!!errors?.proficiency?.message}
                    isLoading={isLanguageProficiencylistLoading}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <Button onClick={handleSubmit(onAdd)}>Add</Button>
        </div>
      </Form>
    </FormProvider>
  );
};

interface IDomainExperienceProps {
  defaultValues?: TDomainExperienceFormData;
  onSubmit: (data: TDomainExperienceFormData) => void;
  selectedDomains?: string[];
  error: boolean;
}

export const DomainExperienceForm: React.FC<IDomainExperienceProps> = ({
  selectedDomains,
  defaultValues,
  onSubmit,
  error,
}) => {
  const form = useForm<TDomainExperienceFormData>({
    resolver: zodResolver(domainExperienceSchema),
    defaultValues,
    values: defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = form;

  const { data: domainsList, isLoading: isDomainsListLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.DOMAIN,
      },
    },
  });
  const { data: domainExperienceList, isLoading: isDomainExperienceListLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.DOMAIN_EXPERIENCE,
      },
    },
  });

  const onAdd: SubmitHandler<TDomainExperienceFormData> = async (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <FormProvider {...form}>
      <Form id="domain-experience-form">
        <div className="flex items-end gap-x-8 pt-8">
          <FormField
            name="domain"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Domain</FormLabel>
                <FormControl>
                  <Select
                    className=""
                    options={
                      domainsList?.items
                        .map((s) => s.description)
                        .filter((s): s is string => s !== undefined && s !== null && !selectedDomains?.includes(s)) ||
                      []
                    }
                    selected={field.value}
                    placeholder="Select Option"
                    onChange={(value) => field.onChange(value)}
                    error={!!errors?.domain?.message || error}
                    isLoading={isDomainsListLoading}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            name="experience"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Years of experience</FormLabel>
                <FormControl>
                  <Select
                    className=""
                    options={
                      domainExperienceList?.items
                        .map((s) => s.description)
                        .filter((s): s is string => s !== undefined && s !== null) || []
                    }
                    selected={field.value}
                    placeholder="Select Option"
                    onChange={(value) => field.onChange(value)}
                    error={!!errors?.experience?.message || error}
                    isLoading={isDomainExperienceListLoading}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          {/* <div className="flex h-full flex-shrink-0 items-end"> */}
          <Button onClick={handleSubmit(onAdd)}>Add</Button>
          {/* </div> */}
        </div>
      </Form>
    </FormProvider>
  );
};
