import { useQuery } from '@tanstack/react-query';

import {
  getCurrentPostMatches,
  getPostById,
  getPostByIdForCandidate,
  getPostEventsById,
  POST_ENDPOINTS,
} from '@/services/post';
import {
  TGetCurrentPostMatchesRequest,
  TGetPostByIdRequest,
  TGetPostEventsRequest,
  TGetPostForCandidateByIdRequest,
} from '@/services/post/post.types';

import { type UseQueryOptions } from '../types';

export function useGetPostById({ reactQueryOptions, params }: UseQueryOptions<TGetPostByIdRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [POST_ENDPOINTS.GET_POST_BY_ID, params.path.id],
    queryFn: ({ signal }) => getPostById({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetPostForCandidateById({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetPostForCandidateByIdRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [POST_ENDPOINTS.GET_POST_BY_ID, params.path.id, params.path.userId],
    queryFn: ({ signal }) => getPostByIdForCandidate({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetCurrentPostMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentPostMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;

  return useQuery({
    ...restOptions,
    queryKey: [POST_ENDPOINTS.GET_CURRENT_POST_MATCHES, ...queryKey],
    queryFn: ({ signal }) => getCurrentPostMatches({ signal, params }),
  });
}

export function useGetPostEventsById({ reactQueryOptions, params }: UseQueryOptions<TGetPostEventsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [POST_ENDPOINTS.GET_EVENTS_BY_POST_ID, params.path.postId],
    queryFn: ({ signal }) => getPostEventsById({ signal, params }),
  });
}
