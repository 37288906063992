import React, { useCallback, useMemo, useState } from 'react';
import { UilAngleLeft, UilAngleRight, UilClockFive } from '@iconscout/react-unicons';
import {
  addDays,
  addMinutes,
  format,
  isEqual,
  isPast,
  isSameDay,
  isSameMonth,
  isValid,
  startOfDay,
  startOfWeek,
} from 'date-fns';

import { Button } from '@/components/ui/button';
import DatePicker from '@/components/ui/date-picker';

import { cn } from '@/lib/utils';

export type TimeSlot = {
  startTime: Date;
  endTime: Date;
};

interface ITimeSlotBookerProps {
  events: TimeSlot[];
  selectedSlot?: TimeSlot | null;
  onSlotSelect?: (slot: TimeSlot | null) => void;
  disabledSlots?: TimeSlot;
}

const MEETING_INTERVAL = 30; // 30-minute interval

const TimeSlotBooker: React.FC<ITimeSlotBookerProps> = ({ events, selectedSlot, onSlotSelect }) => {
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // Memoize the sorting and grouping logic using useCallback
  const groupEventsByWeek = useCallback((events: TimeSlot[]) => {
    // Filter out events with invalid dates and those in the past
    const validFutureEvents = events.filter(
      (event) =>
        isValid(event.startTime) &&
        isValid(event.endTime) &&
        (event.startTime >= startOfDay(new Date()) || !isPast(event.startTime))
    );

    // Sort the events by the earliest startTime
    const sortedEvents = validFutureEvents.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

    // Group events by work week into an array
    return sortedEvents.reduce((acc, event) => {
      const weekStart = startOfWeek(event.startTime, { weekStartsOn: 1 }); // Week starts on Monday

      // Find the group index where the event belongs (if any)
      const group = acc.find((g) => startOfWeek(g[0].startTime, { weekStartsOn: 1 }).getTime() === weekStart.getTime());

      if (group) {
        group.push(event);
      } else {
        acc.push([event]);
      }

      return acc;
    }, [] as TimeSlot[][]);
  }, []);

  // Use useMemo to memoize the grouped events and the includeDates array
  const groupedByWeek = useMemo(() => groupEventsByWeek(events), [events, groupEventsByWeek]);
  const includeDates = useMemo(() => groupedByWeek.map((group) => group[0].startTime).filter(isValid), [groupedByWeek]);
  const currentWeek = groupedByWeek[currentWeekIndex];

  // Generate an array of dates for each workday (Monday to Friday) in the current week
  const daysInCurrentWeek = useMemo(() => {
    if (!currentWeek) return [];
    const weekStart = startOfWeek(currentWeek[0].startTime, { weekStartsOn: 1 });

    // Create an array of 5 days (Monday to Friday) and filter out invalid dates
    return Array.from({ length: 5 })
      .map((_, i) => addDays(weekStart, i))
      .filter(isValid); // Filter out any invalid dates
  }, [currentWeek]);

  const isDaySelected = (day: Date) => {
    if (!selectedDate) return false;
    return isEqual(day, selectedDate);
  };

  const doesDayHaveEvent = useCallback(
    (day: Date) => {
      if (!currentWeek) return false;
      return currentWeek.some((event) => isSameDay(day, event.startTime) || isSameDay(day, event.endTime));
    },
    [currentWeek]
  );

  // Filter events for the selected day
  const eventsForSelectedDay = useMemo(() => {
    if (!selectedDate) return [];
    return currentWeek?.filter((event) => isSameDay(event.startTime, selectedDate)) || [];
  }, [selectedDate, currentWeek]);

  // Generate slot intervals for the selected day
  const timeSlots = useMemo(() => {
    if (eventsForSelectedDay.length === 0) return [];
    const slots: Date[] = [];
    eventsForSelectedDay.forEach((event) => {
      let time = event.startTime;
      while (addMinutes(time, MEETING_INTERVAL) <= event.endTime) {
        slots.push(time);
        time = addMinutes(time, MEETING_INTERVAL);
      }
    });
    return slots;
  }, [eventsForSelectedDay]);

  // If there's no selected date, set it to the first day with events in the current week
  useMemo(() => {
    if (!selectedDate && daysInCurrentWeek.length > 0) {
      const firstDayWithEvents = daysInCurrentWeek.find(doesDayHaveEvent);
      if (firstDayWithEvents) {
        setSelectedDate(firstDayWithEvents);
      }
    }
  }, [selectedDate, daysInCurrentWeek, doesDayHaveEvent]);

  const isSlotSelected = (timeSlot: Date) => {
    if (!selectedSlot) return false;

    return isEqual(selectedSlot.startTime, timeSlot);
  };

  const onClickTimeSlot = (timeSlot: Date) => {
    if (!onSlotSelect) return;
    onSlotSelect({ startTime: timeSlot, endTime: addMinutes(timeSlot, MEETING_INTERVAL) });
  };

  // Handle DatePicker onChange to update the current week index
  const handleDatePickerChange = (date: Date | null) => {
    if (!date) return;

    // Find the corresponding week index
    const weekIndex = groupedByWeek.findIndex((week) => isSameMonth(week[0].startTime, date));

    if (weekIndex !== -1) {
      setCurrentWeekIndex(weekIndex);
      setSelectedDate(null); // Reset selected date
      onSlotSelect && onSlotSelect(null); // Reset selected slot
    }
  };

  const onPreviousClick = () => {
    setCurrentWeekIndex((prev) => Math.max(prev - 1, 0));
    setSelectedDate(null);
    onSlotSelect && onSlotSelect(null); // Reset selected slot
  };

  const onNextClick = () => {
    setCurrentWeekIndex((prev) => Math.min(prev + 1, groupedByWeek.length - 1));
    setSelectedDate(null);
    onSlotSelect && onSlotSelect(null); // Reset selected slot
  };

  return (
    <div>
      <div className="flex items-center justify-between pb-2">
        <DatePicker
          showMonthYearPicker
          useButton
          includeDates={includeDates}
          value={selectedDate?.toISOString()}
          onChange={handleDatePickerChange}
        />
        <div className="flex gap-2">
          <Button
            variant="tertiary"
            className="p-3"
            onClick={onPreviousClick}
            disabled={currentWeekIndex === 0}
          >
            <UilAngleLeft className="size-[1.125rem] text-primary-dark-100" />
          </Button>
          <Button
            variant="tertiary"
            className="p-3"
            onClick={onNextClick}
            disabled={currentWeekIndex === groupedByWeek.length - 1}
          >
            <UilAngleRight className="size-[1.125rem] text-primary-dark-100" />
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-5 justify-between gap-4 py-4">
        {daysInCurrentWeek.map((day, index) => (
          <div key={index}>
            <Button
              variant={'tertiary'}
              className={cn(
                'flex h-auto w-full flex-col gap-3 font-semibold',
                isDaySelected(day) && 'ring-4 ring-primary-dark-10'
              )}
              onClick={() => setSelectedDate(day)}
              autoFocus={isDaySelected(day)}
              disabled={!doesDayHaveEvent(day)}
            >
              <span className="text-sm">{format(day, 'eee')}</span>
              <span className="text-xl">{format(day, 'dd')}</span>
            </Button>
          </div>
        ))}
      </div>
      <div className="flex items-center gap-2 font-semibold">
        <UilClockFive className="size-[1.125rem]" />
        <span className="text-xs leading-[1.125rem]">{MEETING_INTERVAL} minute meeting</span>
      </div>
      {selectedDate && (
        <div className="-mx-2 my-2 grid h-44 grid-cols-3 gap-4 overflow-y-auto px-2 py-2 scrollbar">
          {timeSlots.map((slot, index) => (
            <Button
              key={index}
              variant="tertiary"
              disabled={isPast(slot)}
              onClick={() => onClickTimeSlot(slot)}
              className={cn(isSlotSelected(slot) && 'ring-4 ring-primary-dark-10')}
            >
              {format(slot, 'hh:mm a')}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TimeSlotBooker;
