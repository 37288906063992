import { useQuery } from '@tanstack/react-query';

import { getCurrentUser, TGetCurrentUserRequest, USER_ENDPOINTS } from '@/services/user';

import { type UseQueryOptions } from '../types';

const { GET_CURRENT_USER } = USER_ENDPOINTS;
export function useGetCurrentUser({ reactQueryOptions }: UseQueryOptions<TGetCurrentUserRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_CURRENT_USER],
    queryFn: getCurrentUser,
    staleTime: Infinity,
  });
}
