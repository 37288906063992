import { UilArrowLeft } from '@iconscout/react-unicons';
import { createFileRoute, useRouter } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import FeaturedIcon from '@/components/icons/featured-icon';
import { LoadingRoleDetail } from '@/components/roles/details/loading';
import { MainDetails } from '@/components/roles/details/main-details';
import { RoleCharts } from '@/components/roles/details/role-charts';
import { SidePanel } from '@/components/roles/details/side-panel';

import { useGetCurrentCandidateSingleJobDetails } from '@/hooks/candidate';

import { TCandidateJobPost } from '@/services/candidate';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/role/$roleId')({
  component: RoleDetail,
});

function RoleDetail() {
  const { roleId } = Route.useParams();
  const router = useRouter();

  const { data, isLoading } = useGetCurrentCandidateSingleJobDetails({
    params: {
      path: {
        postId: roleId,
      },
    },
    reactQueryOptions: {
      enabled: Boolean(roleId),
    },
  });

  if (isLoading) return <LoadingRoleDetail />;

  if (!data) return <NoPostDetail />;

  return (
    <div>
      <section className="w-full pb-8">
        <Button
          variant="tertiary"
          onClick={() => router.history.back()}
        >
          <UilArrowLeft size={18} />
          Back
        </Button>
      </section>
      <main className="flex space-x-8">
        <section className="w-[420px] max-w-[320px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
          <SidePanel post={data as TCandidateJobPost} />
        </section>
        <section className="flex flex-col space-y-8">
          <MainDetails post={data as TCandidateJobPost} />
          <RoleCharts role={data as TCandidateJobPost} />
        </section>
      </main>
    </div>
  );
}

const NoPostDetail = () => {
  return (
    <div className="flex min-h-[525px] items-center justify-center text-center">
      <div className="mt-32 flex max-w-md flex-col items-center space-y-4">
        <FeaturedIcon
          variant="warning"
          size="lg"
        />
        <div className="space-y-2">
          <h4 className="font-semibold">Post not found</h4>
          <p className="text-xs text-primary-dark-60">The post you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
};
