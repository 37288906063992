import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import {
  CANDIDATE_ENDPOINTS,
  getBookedTimeslots,
  getCurrentCandidate,
  getCurrentCandidateAppliedMatches,
  getCurrentCandidateArchivedJobPosts,
  getCurrentCandidateHiredMatches,
  getCurrentCandidateMatches,
  getCurrentCandidateSingleJobDetails,
  getSavedPost,
  TGetBookedTimeslotsRequest,
  TGetCurrentCandidateAppliedMatchesRequest,
  TGetCurrentCandidateHiredMatchesRequest,
  TGetCurrentCandidateMatchesRequest,
  TGetCurrentCandidateRequest,
  TGetCurrentCandidateSingleJobDetailsRequest,
  TGetSavedPostRequest,
} from '@/services/candidate';

import { type UseQueryOptions } from '../types';

const { GET_CURRENT_CANDIDATE, GET_BOOKED_TIMESLOTS } = CANDIDATE_ENDPOINTS;
export function useGetCurrentCandidate({ reactQueryOptions }: UseQueryOptions<TGetCurrentCandidateRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_CURRENT_CANDIDATE],
    queryFn: getCurrentCandidate,
    staleTime: Infinity,
  });
}

export function useGetCurrentCandidateSuspense({ reactQueryOptions }: UseQueryOptions<TGetCurrentCandidateRequest>) {
  return useSuspenseQuery({
    ...reactQueryOptions,
    queryKey: [GET_CURRENT_CANDIDATE],
    queryFn: getCurrentCandidate,
    staleTime: Infinity,
  });
}

export function useGetBookedTimeslots({ reactQueryOptions, params }: UseQueryOptions<TGetBookedTimeslotsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_BOOKED_TIMESLOTS],
    queryFn: () =>
      getBookedTimeslots({
        params: {
          query: {
            ...params?.query,
            limit: params?.query?.limit || '0',
            offset: params?.query?.offset || '0',
          },
        },
      }),
  });
}

export function useGetCurrentCandidateMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_MATCHES, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateMatches({ signal, params }),
  });
}

export function useGetCurrentCandidateSingleJobDetails({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateSingleJobDetailsRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_SINGLE_JOB_POST, ...queryKey, params.path.postId],
    queryFn: ({ signal }) => getCurrentCandidateSingleJobDetails({ signal, params }),
  });
}

export function useGetCurrentCandidateAppliedMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateAppliedMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_APPLIED_MATCHES, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateAppliedMatches({ signal, params }),
  });
}

export function useGetCurrentCandidateHiredMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateHiredMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;
  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_HIRED_MATCHES, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateHiredMatches({ signal, params }),
  });
}

export function useGetCurrentCandidateArchivedMatches({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCurrentCandidateAppliedMatchesRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;

  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_ARCHIVED_JOB_POST, ...queryKey],
    queryFn: ({ signal }) => getCurrentCandidateArchivedJobPosts({ signal, params }),
  });
}

export function useGetSavedPost({ reactQueryOptions = {}, params }: UseQueryOptions<TGetSavedPostRequest>) {
  const { queryKey = [], ...restOptions } = reactQueryOptions;

  return useQuery({
    ...restOptions,
    queryKey: [CANDIDATE_ENDPOINTS.GET_SAVED_POST, ...queryKey],
    queryFn: ({ signal }) => getSavedPost({ signal, params }),
  });
}
