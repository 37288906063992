import * as React from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

// Todo: Update colors for variants other than fill
const toggleVariants = cva(
  'data-[state=on]:fill-primary-dark-600 [data-[state=on]&>svg]: fill inline-flex items-center justify-center rounded-full text-sm font-medium ring-offset-white transition-colors data-[state=on]:bg-slate-100 data-[state=on]:bg-transparent data-[state=on]:text-slate-900 hover:bg-primary-dark-5 hover:text-primary-dark-60 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-blue-10 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-transparent',
      },
      size: {
        default: 'size-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleVariants({ variant, size, className }))}
    {...props}
  />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
