import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  type DatePickerProps as ReactDatePickerProps,
} from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { useEffect, useRef, useState } from 'react';
import { UilAngleDown, UilAngleLeft } from '@iconscout/react-unicons';
import { eachYearOfInterval, endOfYear, format } from 'date-fns';

import { cn } from '@/lib/utils';

import { Button } from './button';
import { Input, inputVariants } from './input';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { ScrollArea } from './scroll-area';

type IProps = ReactDatePickerProps & {
  error?: boolean;
  useButton?: boolean;
};

interface ICustomHeaderProps {
  headerProps: ReactDatePickerCustomHeaderProps;
  datePickerProps: IProps;
}

interface IYearDropDownProps {
  currentMonth: string;
  currentYear: number;
  changeYear: (year: number) => void;
  minDate: Date;
  maxDate: Date;
}

interface IYearSelectorProps {
  currentYear: string;
  onSelectYear: (year: string) => void;
  yearOptions: string[];
}

const YearSelector: React.FC<IYearSelectorProps> = ({ currentYear, onSelectYear, yearOptions }) => {
  const viewportRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLButtonElement>(null);

  const isSelected = (option: string) => {
    return currentYear.toString() === option;
  };

  useEffect(() => {
    if (selectedRef.current && viewportRef.current) {
      const selectedButton = selectedRef.current;
      const viewport = viewportRef.current;
      // Scroll the viewport to the selected button
      viewport.scrollTo({
        top: selectedButton.offsetTop - viewport.clientHeight / 2 + selectedButton.clientHeight / 2,
      });
    }
  }, []);

  return (
    <ScrollArea
      className="h-52 py-1"
      type="auto"
      viewportRef={viewportRef}
    >
      <div className="mb-1 grid grid-cols-4 gap-1 text-base">
        {yearOptions.map((option) => (
          <Button
            variant="ghost"
            key={option}
            className={cn(
              'p-4 text-base font-medium hover:bg-primary-dark-5',
              isSelected(option) && 'border border-primary-blue-100 text-primary-blue-100 hover:bg-primary-white-100'
            )}
            onClick={(e) => {
              e.preventDefault();
              onSelectYear(option);
            }}
            ref={isSelected(option) ? selectedRef : undefined}
          >
            {option}
          </Button>
        ))}
      </div>
    </ScrollArea>
  );
};

const YearDropDown: React.FC<IYearDropDownProps> = ({ currentYear, currentMonth, changeYear, minDate, maxDate }) => {
  const [open, setOpen] = useState(false);

  const yearOptions = eachYearOfInterval({ start: minDate, end: maxDate }).map((date) => date.getFullYear().toString());

  const onSelectedYear = (year: string) => {
    changeYear(Number(year));
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
    >
      <PopoverTrigger asChild>
        <Button
          className="flex items-center text-lg font-medium [&[data-state=open]>svg]:rotate-180"
          variant={'ghost'}
        >
          <span>
            {currentMonth} {currentYear}
          </span>
          <UilAngleDown className="size-6 text-primary-dark-60 transition-transform duration-200" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        alignOffset={-10.5}
        className="w-[20.75rem] p-0 px-2"
      >
        <YearSelector
          currentYear={currentYear.toString()}
          onSelectYear={onSelectedYear}
          yearOptions={yearOptions}
        />
      </PopoverContent>
    </Popover>
  );
};

const CustomHeader: React.FC<ICustomHeaderProps> = ({ headerProps, datePickerProps }) => {
  const {
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
    nextYearButtonDisabled,
    prevYearButtonDisabled,
    increaseMonth,
    increaseYear,
    decreaseYear,
    decreaseMonth,
    date,
    changeYear,
  } = headerProps;
  const {
    minDate = new Date(1900, 0, 1),
    maxDate = endOfYear(new Date()),
    showYearDropdown,
    showYearPicker,
    showMonthYearPicker,
  } = datePickerProps;

  const onNextClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if ((showYearPicker || showMonthYearPicker) && !nextYearButtonDisabled) {
      return increaseYear();
    }

    if (!nextMonthButtonDisabled) {
      return increaseMonth();
    }
  };

  const onPrevClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if ((showYearPicker || showMonthYearPicker) && !prevYearButtonDisabled) {
      return decreaseYear();
    }

    if (!prevMonthButtonDisabled) {
      return decreaseMonth();
    }
  };

  const monthName = format(date, 'MMMM');
  const year = format(date, 'yyyy');

  const renderMonthLabel = () => {
    if (showYearPicker || showMonthYearPicker || showYearDropdown) {
      return null;
    }

    return <span>{monthName}</span>;
  };

  const renderYearLabel = () => {
    if (showYearPicker) {
      return null;
    }

    if (showYearDropdown)
      return (
        <YearDropDown
          currentMonth={monthName}
          currentYear={date.getFullYear()}
          changeYear={changeYear}
          minDate={minDate}
          maxDate={maxDate}
        />
      );

    return year;
  };

  return (
    <div
      className="flex items-center justify-between px-6 text-start text-lg"
      id="custom-datepicker-header"
    >
      <div className="flex items-center gap-1.5">
        {renderMonthLabel()}
        {renderYearLabel()}
      </div>
      <div className="flex items-center gap-x-2">
        <Button
          variant={'ghost'}
          disabled={prevMonthButtonDisabled}
          onClick={onPrevClick}
        >
          <UilAngleLeft className="size-6 text-primary-dark-60" />
        </Button>
        <Button
          onClick={onNextClick}
          disabled={nextMonthButtonDisabled}
          variant={'ghost'}
        >
          <UilAngleLeft className="size-6 rotate-180 text-primary-dark-60" />
        </Button>
      </div>
    </div>
  );
};

const DatePicker: React.FC<IProps> = ({
  error,
  useButton,
  dateFormat = 'dd/MM/yyyy',
  minDate = new Date(1900, 0, 1),
  maxDate = endOfYear(new Date()),
  ...props
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return (
    <ReactDatePicker
      formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 1)}
      showPopperArrow={false}
      wrapperClassName="!block"
      popperClassName="shadow-lg !z-[11]"
      calendarClassName="!bg-primary-white-100 !border-primary-dark-10 !rounded-lg !font-montserrat !font-medium !text-base !pt-1.5"
      renderCustomHeader={(headerProps) => (
        <CustomHeader
          headerProps={headerProps}
          datePickerProps={props}
        />
      )}
      customInput={
        useButton ? (
          <button
            className={cn(
              inputVariants({ variant: error ? 'error' : 'default' }),
              'flex min-w-52 justify-between',
              isCalendarOpen && 'border-primary-blue-40 outline-none ring-4 ring-primary-blue-10 [&>svg]:rotate-180'
            )}
            type="button"
          >
            <span>{format(props?.value || new Date(), 'MMM yyyy')}</span>
            <UilAngleDown
              className={cn('pointer-events-none size-6 text-primary-dark-60 transition-transform duration-200')}
            />
          </button>
        ) : (
          <Input error={error} />
        )
      }
      dateFormat={dateFormat}
      popperPlacement="bottom-start"
      popperProps={{ strategy: 'absolute' }}
      {...props}
      minDate={minDate}
      maxDate={maxDate}
      onCalendarOpen={() => setIsCalendarOpen(true)}
      onCalendarClose={() => setIsCalendarOpen(false)}
    />
  );
};

export default DatePicker;
