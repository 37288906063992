import React from 'react';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui//button';

import useStepper from '@/hooks/useStepper';

export interface IOnboardingFooterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  handleNext?: () => void;
}

const OnboardingFooter: React.FC<IOnboardingFooterProps> = ({ isDisabled, isLoading, handleNext }) => {
  const { currentStep, goToPreviousStep } = useStepper();

  const stepsContainer = document.getElementById('steps-container');
  if (!stepsContainer) return null;

  return createPortal(
    <section className="fixed bottom-0 flex w-screen items-center justify-center overflow-hidden bg-primary-white-100">
      <div className="flex w-full max-w-[880px] justify-between p-8">
        <Button
          variant="tertiary"
          size={'lg'}
          onClick={goToPreviousStep}
          className={currentStep == 1 ? 'invisible' : 'visible'}
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          type="submit"
          disabled={isDisabled || isLoading}
          loading={isLoading}
          size={'lg'}
          className="min-w-24"
        >
          {currentStep === 8 ? 'Done' : 'Next'}
        </Button>
      </div>
    </section>,
    stepsContainer
  );
};

export default OnboardingFooter;
