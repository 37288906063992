import { useState } from 'react';
import { UilAngleDown, UilCheck } from '@iconscout/react-unicons';

import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from '@/components/ui/command';

import { cn } from '@/lib/utils';

import { inputVariants } from './input';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export type OptionType = {
  value: string;
};

interface SelectProps {
  options: string[];
  selected: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
  error?: boolean;
  className?: string;
  isLoading?: boolean;
}

export function Select({
  options,
  selected,
  onChange,
  placeholder = 'Select Option',
  error,
  isLoading,
  ...props
}: SelectProps) {
  const sortedOptions = [...options].sort((a, b) => a.localeCompare(b));
  const [open, setIsOpen] = useState(false);
  const handleSelect = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const isSelected = (option: string) => {
    return selected?.includes(option);
  };

  return (
    <Command {...props}>
      <Popover
        open={open}
        onOpenChange={setIsOpen}
      >
        <PopoverTrigger asChild>
          <button
            className={cn(
              inputVariants({ variant: error ? 'error' : 'default' }),
              'relative w-full justify-between [&[data-state=open]>svg]:rotate-180'
            )}
          >
            <span className={selected ? 'text-primary-dark-100' : 'text-primary-dark-60'}>
              {selected || placeholder}
            </span>
            <UilAngleDown
              className={cn('pointer-events-none size-6 text-primary-dark-60 transition-transform duration-200')}
            />
          </button>
        </PopoverTrigger>
        <PopoverContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          className="PopoverContent p-0"
          asChild
          sideOffset={8}
        >
          <CommandList className="max-w-full bg-primary-white-100">
            <CommandEmpty>{isLoading ? 'Loading...' : 'No results found'}.</CommandEmpty>
            <CommandGroup className="p-0">
              {sortedOptions.map((o) => (
                <CommandItem
                  className="inline-flex w-full items-center justify-between"
                  key={o}
                  onSelect={() => handleSelect(o)}
                  isSelected={isSelected(o)}
                >
                  <span>{o}</span>
                  <span>{isSelected(o) && <UilCheck className="size-5 text-primary-blue-100" />}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </PopoverContent>
      </Popover>
    </Command>
  );
}
