import { useFormContext, useFormState } from 'react-hook-form';

import { Button } from '@/components/ui/button';

import { cn } from '@/lib/utils';

interface IFormFooterProps {
  onSubmit: () => void;
  isSubmitting?: boolean;
  submitButtonText?: string;
  submittingText?: string;
  className?: string;
}

const FormFooter = ({
  onSubmit,
  className,
  submitButtonText = 'Save changes',
  submittingText = 'Saving...',
  isSubmitting,
}: IFormFooterProps) => {
  const formStateContext = useFormState();
  const formContext = useFormContext();
  const { isDirty } = formStateContext;

  const handleClick = (handler: () => void) => {
    if (!isDirty) return;
    handler();
  };

  if (!formStateContext) {
    throw new Error('useFormField should be used within <FormProvider>');
  }

  return (
    <div className={cn('flex justify-end gap-x-4 pt-8', className)}>
      <Button
        variant="tertiary"
        onClick={(e) => {
          e.preventDefault();
          handleClick(formContext.reset);
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={isSubmitting}
        onClick={(e) => {
          e.preventDefault();
          handleClick(onSubmit);
        }}
        loading={isSubmitting}
        loadingText={submittingText}
        className="min-w-[8.188rem]"
      >
        {submitButtonText}
      </Button>
    </div>
  );
};

export default FormFooter;
