import { UilSuitcaseAlt } from '@iconscout/react-unicons';
import { Link, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Tag } from '@/components/ui/tag';

import BusinessAvatarWithLabel from '@/components/business-avatar-with-label';
import FeaturedIcon from '@/components/icons/featured-icon';
import MatchPercentLabel from '@/components/tags/match-percent-label';

import { useGetCurrentCandidateMatches } from '@/hooks/candidate';

import { TCurrentCandidateMatch } from '@/services/candidate';

import { REMOTE_WORK_OPTIONS } from '@/constants/post';

const RecentMatchCard = ({ match }: { match: TCurrentCandidateMatch }) => {
  const navigate = useNavigate();
  return (
    <Card className="flex items-center justify-between">
      <div className="space-y-6">
        <BusinessAvatarWithLabel
          src={match?.business?.logo_url}
          name={match?.business?.name}
          location={match?.location}
        />
        <div className="space-y-2">
          <div className="inline-flex items-center gap-2">
            <UilSuitcaseAlt className="default-icon-sm" />
            <span className="font-semibold">{match.title}</span>
          </div>
          <div className="flex items-center gap-2">
            <Tag variant={'primary'}>{match.remote_work === REMOTE_WORK_OPTIONS.YES ? 'Remote' : 'On-site'}</Tag>
            <Tag variant={'primary'}>{match.employment_type}</Tag>
            <MatchPercentLabel
              percent={80}
              hideLabel
            />
          </div>
        </div>
      </div>
      <Button onClick={() => navigate({ to: `/candidate/role/${match.id}` })}>Learn More</Button>
    </Card>
  );
};

const NoMatches = () => {
  return (
    <div className="flex min-h-[525px] justify-center text-center">
      <div className="mt-32 flex max-w-md flex-col items-center space-y-4">
        <FeaturedIcon
          variant="warning"
          size="lg"
        />
        <div className="space-y-2">
          <h4 className="font-semibold">No new matches</h4>
          <p className="text-xs text-primary-dark-60">
            You don&apos;t have any new matches. Take a look at your profile to see if there are any changes that you
            could make to stand out.
          </p>
        </div>
      </div>
    </div>
  );
};

export const RecentMatches = () => {
  const { data: matches, isLoading } = useGetCurrentCandidateMatches({
    params: {
      query: {
        limit: '3',
        offset: '0',
      },
    },
    reactQueryOptions: {
      queryKey: ['recent-matches'],
    },
  });

  return (
    <Card className="space-y-4">
      <div className="flex items-center justify-between">
        <CardTitle>Recent matches</CardTitle>
        <Link to="/candidate/roles">
          <Button variant="tertiary">View all</Button>
        </Link>
      </div>
      <div className="space-y-4">
        {isLoading &&
          new Array(3).fill(null).map((_, index) => (
            <Skeleton
              key={index}
              className="h-[205px]"
            />
          ))}
        {matches?.items.length === 0 && <NoMatches />}
        {matches?.items.map((match) => (
          <RecentMatchCard
            key={match.id}
            match={match}
          />
        ))}
      </div>
    </Card>
  );
};
