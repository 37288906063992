import { useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';

import { useGetCurrentCandidate } from '@/hooks/candidate';

import { CultureName } from '@/services/candidate';

import { parseCandidateCulture } from '@/utils/string';

import { cn } from '@/lib/utils';

import { CULTURE_TO_EMOJI_MAP } from '@/constants/candidate';

import LoadingCard from './loading-card';

const CULTURE_CLASS_NAME_MAP: Record<CultureName, string> = {
  Clan: 'bg-primary-blue-5 border-primary-blue-40',
  Adhocracy: 'bg-secondary-purple-5 border-secondary-purple-40',
  Market: 'bg-secondary-green-5 border-secondary-green-40',
  Hierarchy: 'bg-secondary-yellow-5 border-secondary-yellow-40',
};

const CultureCard = () => {
  const { data: candidate, isLoading } = useGetCurrentCandidate({});
  const navigate = useNavigate();

  if (isLoading) return <LoadingCard />;

  if (!candidate?.culture) return null;

  const culture = parseCandidateCulture(candidate.culture);
  if (!culture || culture.length === 0) return null;

  const highestRankCulture = culture.reduce((highest, current) => (current.rank < highest.rank ? current : highest));

  return (
    <Card className="space-y-3">
      <CardTitle>Culture</CardTitle>
      <CardContent className="space-y-4">
        <div
          className={cn(
            'flex items-center gap-x-4 rounded-lg border px-4 py-4 3xl:gap-x-8 3xl:px-8',
            CULTURE_CLASS_NAME_MAP[highestRankCulture.name]
          )}
        >
          <span className="text-7xl 3xl:text-8xl">{CULTURE_TO_EMOJI_MAP[highestRankCulture.name]}</span>
          <div className="space-y-1 font-semibold">
            <p className="text-sm text-primary-dark-40">Culture:</p>
            <p className="text-xl">{highestRankCulture.name}</p>
          </div>
        </div>

        <div className="flex w-full space-x-4">
          <Button
            className="flex-1"
            onClick={() => navigate({ to: '/candidate/quiz/culture/result' })}
          >
            View score
          </Button>
          <Button
            variant="tertiary"
            className="flex-1"
            onClick={() => navigate({ to: '/candidate/quiz/culture' })}
          >
            Re-take the quiz
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default CultureCard;
