import { useEffect, useState } from 'react';
import { UilTimes } from '@iconscout/react-unicons';
import { createFileRoute } from '@tanstack/react-router';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';

import { Badge } from '@/components/ui/badge';
import ComboBoxMultipleSelect from '@/components/ui/combo-box-multiple';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select';

import { DomainExperienceForm } from '@/components/forms/coupled-select-option';
import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useGetAllSkillsets } from '@/hooks/skillsets';
import useStepper from '@/hooks/useStepper';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import {
  getArrayFromString,
  getFormattedDataForAPI,
  getFormattedDomainExperienceForAPI,
  parseFormattedDomainExperience,
} from '@/utils/format';

import { VALUES_GROUP } from '@/constants/values-map';

import { TDomainExperienceFormData } from '@/validation-schemas/coupled-fields.schema';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/_steps/step4')({
  component: Step4,
});

type FormValues = {
  technical_skills: string[];
  industry_experience: string[];
  business_experience: string[];
  domain_exp: TDomainExperienceFormData[];
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: Record<keyof FormValues, { type: string; message: string }> = {} as Record<
    keyof FormValues,
    { type: string; message: string }
  >;

  if (!values.technical_skills || values.technical_skills.length === 0) {
    errors.technical_skills = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.industry_experience || values.industry_experience.length === 0) {
    errors.industry_experience = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.business_experience || values.business_experience.length === 0) {
    errors.business_experience = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.domain_exp || values.domain_exp.length === 0) {
    errors.domain_exp = {
      type: 'required',
      message: 'This is required.',
    };
  }

  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};

function Step4() {
  const { data } = useGetCurrentCandidateSuspense({});
  const [skillsetQuery, setSkillsetQuery] = useState('');
  const [domains, setDomains] = useState<TDomainExperienceFormData[]>([]);

  const { goToNextStep } = useStepper();

  const form = useForm<FormValues>({
    defaultValues: {
      technical_skills: [],
      industry_experience: [],
      business_experience: [],
      domain_exp: [],
    },
    resolver,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = form;

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: { onSuccess: goToNextStep },
  });

  useEffect(() => {
    if (data) {
      const initialValues: FormValues = {
        technical_skills: getArrayFromString(data.technical_skills) || [],
        industry_experience: getArrayFromString(data.industry_experience) || [],
        business_experience: getArrayFromString(data.business_experience) || [],
        domain_exp: parseFormattedDomainExperience(data?.domain_exp || '') || [],
      };
      setDomains(initialValues.domain_exp);
      reset(initialValues);
    }
  }, [data, reset]);

  const { data: businessTypesList, isLoading: isBusinessTypesLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.BUSINESS_TYPE,
      },
    },
  });

  const { data: industryTypesList, isLoading: isIndustryTypesLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.INDUSTRY_TYPE,
      },
    },
  });

  const { data: technicalSkillsList, isLoading: isTechnicalSkillsLoading } = useGetAllSkillsets({
    params: {
      query: {
        q: skillsetQuery,
      },
    },
  });

  const handleDomainSubmit = (data: TDomainExperienceFormData) => {
    setDomains((prev) => {
      let updatedDomains;

      const domainExists = prev.some((dom) => dom.domain === data.domain);

      if (domainExists) {
        // Remove the existing domain
        updatedDomains = prev.filter((dom) => dom.domain !== data.domain);
      } else {
        // Add the new domain
        updatedDomains = [...prev, data];
      }

      // Update the form values with the new domains list
      setValue('domain_exp', updatedDomains, { shouldValidate: true, shouldDirty: true });

      return updatedDomains;
    });
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { domain_exp, ...rest } = data;
    const formattedDomains = getFormattedDomainExperienceForAPI(domain_exp);
    updateCandidate({ body: { ...getFormattedDataForAPI(rest), ...formattedDomains } });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="business_experience"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Business types</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  options={
                    businessTypesList?.items
                      .map((s) => s.description)
                      .filter((s): s is string => s !== undefined && s !== null) || []
                  }
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.business_experience?.message}
                  isLoading={isBusinessTypesLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="industry_experience"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Industry types</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  placeholder="Start typing or select"
                  options={
                    industryTypesList?.items
                      .map((s) => s.description)
                      .filter((s): s is string => s !== undefined && s !== null) || []
                  }
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.industry_experience?.message}
                  enableSearch
                  isLoading={isIndustryTypesLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="technical_skills"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Technical skills</FormLabel>
              <FormControl>
                <ComboBoxMultipleSelect
                  placeholder="Start typing or select"
                  options={
                    technicalSkillsList?.items
                      .map((s) => s.category)
                      .filter((s): s is string => s !== undefined && s !== null) || []
                  }
                  selected={field.value}
                  onSelect={(value) => field.onChange(value)}
                  isLoading={isTechnicalSkillsLoading}
                  onQueryChange={(query) => setSkillsetQuery(query)}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <div className="flex-col space-y-2">
        <DomainExperienceForm
          selectedDomains={domains?.map((s) => s.domain)}
          onSubmit={handleDomainSubmit}
          error={!!errors.domain_exp}
        />
        {domains?.length > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            {domains.map((s) => (
              <Badge
                key={s.domain}
                variant="success"
                hideDot
                onClick={() => handleDomainSubmit(s)}
                className="cursor-pointer"
              >
                <span>
                  {s.domain} - {s.experience}
                </span>
                <UilTimes className="mt-0.5 size-3.5 text-primary-success-80" />
              </Badge>
            ))}
          </div>
        )}
      </div>
      <OnboardingFooter
        isLoading={isUpdatingCandidate}
        handleNext={handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
}
