import React from 'react';

import { Progress } from '@/components/ui/progress';

import { Logo } from '@/components/logo';

import useStepper from '@/hooks/useStepper';

const stepDetails = [
  {
    title: 'Getting started',
    description: 'What type of work are you looking for?',
  },
  {
    title: 'Getting started',
    description: 'What are your expectations?',
  },
  {
    title: 'Experience',
    description: 'What are your last 3 roles?',
  },
  {
    title: 'Experience',
    description: 'What kind of businesses have you worked in?',
  },
  {
    title: 'Education',
    description: 'What level of education do you have?',
  },
  {
    title: 'Profile',
    description: 'Upload a photo of yourself to stand out with clients.',
  },
  {
    title: 'Bio & extras',
    description: 'Tell us about yourself! Highlight your passions, skills, experiences, achievements and values.',
  },
  {
    title: 'Bio & extras',
    description: 'Tell us about yourself! Upload your CV and and your website or social links.',
  },
];

const OnboardingHeader: React.FC = () => {
  const { currentStep } = useStepper();

  if (currentStep === 0) {
    return null;
  }

  return (
    <section className="w-full items-center justify-center">
      <div className="w-full py-6 3xl:py-8">
        <Progress value={((currentStep - 1) / 7) * 100} />
      </div>
      <div className="flex w-full flex-col px-8">
        <div className="py-2">
          <Logo variant="mini" />
        </div>
        <div className="flex w-full flex-row items-center justify-between py-4 3xl:pt-8">
          <h1 className="text-2xl font-semibold leading-loose text-primary-dark-100">
            {stepDetails[currentStep - 1].title}
          </h1>
          <span className="text-base font-semibold leading-normal text-primary-dark-60">{currentStep} of 8</span>
        </div>
        <div className="py-2 text-base font-medium leading-normal text-primary-dark-60 3xl:py-4">
          {stepDetails[currentStep - 1].description}
        </div>
      </div>
    </section>
  );
};

export default OnboardingHeader;
