import { useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { PersonalityArchetypeCode } from '@/services/candidate';

import { parsePersonalityDescription } from '@/utils/string';

import { cn } from '@/lib/utils';

import { PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

import LoadingCard from './loading-card';

const PERSONALITY_ClASS_MAP: Record<PersonalityArchetypeCode, string> = {
  X: 'bg-primary-warning-5 border-primary-warning-40',
  H: 'bg-secondary-yellow-5 border-secondary-yellow-40',
  O: 'bg-primary-blue-5 border-primary-blue-40',
  A: 'bg-secondary-red-5 border-secondary-red-40',
  E: 'bg-secondary-green-5 border-secondary-green-40',
  C: 'bg-secondary-purple-5 border-secondary-purple-40',
};

const PersonalityCard = () => {
  const { data: candidate, isLoading } = useGetCurrentCandidate({});
  const { data: personalityValuesMap, isLoading: isPersonalityValuesMapLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.PERSONALITY_FACTORS,
        offset: '0',
        limit: '6',
      },
    },
  });
  const navigate = useNavigate();

  if (isLoading || isPersonalityValuesMapLoading) return <LoadingCard />;

  const personality = personalityValuesMap?.items?.find((item) => item.code === candidate?.personality_archetype);

  if (!personality || !personality?.description) return null;

  const personalityCode = candidate?.personality_archetype as PersonalityArchetypeCode;
  const personalityDescription = parsePersonalityDescription(personality.description);

  return (
    <Card className="space-y-3">
      <CardTitle>Personality</CardTitle>
      <CardContent className="space-y-4">
        <div
          className={cn(
            'flex items-center gap-x-4 rounded-lg border px-4 py-4 3xl:gap-x-8 3xl:px-8',
            PERSONALITY_ClASS_MAP[personalityCode]
          )}
        >
          <span className="text-7xl 3xl:text-8xl">{PERSONALITY_CODE_TO_EMOJI_MAP[personalityCode]}</span>
          <div className="space-y-1 font-semibold">
            <p className="text-sm text-primary-dark-40">ARCHETYPE:</p>
            <p className="text-xl">{personalityDescription?.name}</p>
          </div>
        </div>
        <div className="flex w-full space-x-4">
          <Button
            className="flex-1"
            onClick={() => navigate({ to: '/candidate/quiz/personality/result' })}
          >
            View score
          </Button>
          <Button
            variant="tertiary"
            className="flex-1"
            onClick={() => navigate({ to: '/candidate/quiz/personality' })}
          >
            Re-take the quiz
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default PersonalityCard;
