import { Logo } from '@/components/logo';

interface IProps {
  children: React.ReactNode;
}
const SideBarContainer: React.FC<IProps> = ({ children }) => {
  return (
    <div className="sticky top-0 flex h-screen w-[280px] flex-col gap-8 border-r border-primary-dark-10 bg-white px-4 py-8 3xl:w-[314px]">
      <Logo />
      <div className="flex-1">{children}</div>
    </div>
  );
};

export default SideBarContainer;
