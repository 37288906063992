import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/role/$roleId')({
  component: RoleDetail,
});

function RoleDetail() {
  return (
    <div>
      <main className="flex space-x-8">
        <section className="w-[420px] max-w-[320px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]"></section>
        <section className="flex grow bg-primary-white-100"></section>
      </main>
    </div>
  );
}
