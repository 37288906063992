import { createFileRoute } from '@tanstack/react-router';

import { Skeleton } from '@/components/ui/skeleton';

import CultureCard from '@/components/dashboard/candidate/culture-card';
import PersonalityCard from '@/components/dashboard/candidate/personality-card';
import ProfileCard from '@/components/dashboard/candidate/profile-card';
import { RecentMatches } from '@/components/dashboard/candidate/recent-matches';
import Highlight from '@/components/dashboard/highlight';

import { useGetCurrentUser } from '@/hooks/user';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/')({
  component: DashboardHome,
});

function DashboardHome() {
  const { data: user, isLoading } = useGetCurrentUser({});

  return (
    <main className="space-y-8">
      <div>
        {isLoading ? (
          <div className="space-y-2">
            <Skeleton className="h-7 w-60" />
            <Skeleton className="h-6 w-80" />
          </div>
        ) : (
          <>
            <h1 className="text-3xl font-semibold">Hello, {user?.first_name}</h1>
            <p className="text-sm text-primary-dark-60">Here’s what’s going on today!</p>
          </>
        )}
      </div>
      <div className="flex gap-8">
        <section className="flex-1 space-y-8">
          <div>
            <Highlight
              title="The Science of Upplft"
              subTitle="How we match talent and why there’s no such thing as a perfect match."
              buttonText="Read"
              redirectUrl="https://www.upplft.com/science-of-upplft"
            />
          </div>
          <RecentMatches />
        </section>
        <section className="w-full max-w-[380px] space-y-8 3xl:max-w-[460px]">
          <ProfileCard />
          <PersonalityCard />
          <CultureCard />
        </section>
      </div>
    </main>
  );
}
