import { createFileRoute } from '@tanstack/react-router';

import useAuth from '@/hooks/useAuth';

export const Route = createFileRoute('/unverified')({
  component: Unverified,
});

function Unverified() {
  const { logout } = useAuth();
  return (
    <div>
      Hello /unverified!
      <button onClick={logout}>Logout</button>
    </div>
  );
}
