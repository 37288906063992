import { z } from 'zod';

export const expectationsFormSchema = z
  .object({
    expectation_currency: z.string(),
    expectation_annual_salary_from: z.coerce.number().min(0).nullable(),
    expectation_annual_salary_to: z.coerce.number().min(0).nullable(),
    expectation_day_rate_from: z.coerce.number().min(0).nullable(),
    expectation_day_rate_to: z.coerce.number().min(0).nullable(),
    expectation_hourly_rate_from: z.coerce.number().min(0).nullable(),
    expectation_hourly_rate_to: z.coerce.number().min(0).nullable(),
    other_expectations: z.array(z.string()),
  })
  .superRefine((data, ctx) => {
    // Check if at least one pair of "from" and "to" fields is filled
    const isAnyFilled =
      (data.expectation_annual_salary_from && data.expectation_annual_salary_to) ||
      (data.expectation_day_rate_from && data.expectation_day_rate_to) ||
      (data.expectation_hourly_rate_from && data.expectation_hourly_rate_to);

    const otherExpecations = data.other_expectations;
    if (otherExpecations.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fill in at least one additional expectation.',
        path: ['other_expectations'],
      });
    }

    if (!isAnyFilled) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fill in at least one pair of "from" and "to" fields.',
        path: ['expectation_annual_salary_from'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fill in at least one pair of "from" and "to" fields.',
        path: ['expectation_annual_salary_to'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fill in at least one pair of "from" and "to" fields.',
        path: ['expectation_day_rate_from'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fill in at least one pair of "from" and "to" fields.',
        path: ['expectation_day_rate_to'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fill in at least one pair of "from" and "to" fields.',
        path: ['expectation_hourly_rate_from'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fill in at least one pair of "from" and "to" fields.',
        path: ['expectation_hourly_rate_to'],
      });
    }

    // Check if the "from" field is filled without the corresponding "to" field
    if (data.expectation_annual_salary_from && !data.expectation_annual_salary_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Annual salary to" must be filled if "Annual salary from" is filled.',
        path: ['expectation_annual_salary_to'],
      });
    }
    if (!data.expectation_annual_salary_from && data.expectation_annual_salary_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Annual salary from" must be filled if "Annual salary to" is filled.',
        path: ['expectation_annual_salary_from'],
      });
    }
    if (data.expectation_day_rate_from && !data.expectation_day_rate_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Day rate to" must be filled if "Day rate from" is filled.',
        path: ['expectation_day_rate_to'],
      });
    }
    if (!data.expectation_day_rate_from && data.expectation_day_rate_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Day rate from" must be filled if "Day rate to" is filled.',
        path: ['expectation_day_rate_from'],
      });
    }
    if (data.expectation_hourly_rate_from && !data.expectation_hourly_rate_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Hourly rate to" must be filled if "Hourly rate from" is filled.',
        path: ['expectation_hourly_rate_to'],
      });
    }
    if (!data.expectation_hourly_rate_from && data.expectation_hourly_rate_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Hourly rate from" must be filled if "Hourly rate to" is filled.',
        path: ['expectation_hourly_rate_from'],
      });
    }

    // Check if "to" values are greater than "from" values
    if (
      data.expectation_annual_salary_from &&
      data.expectation_annual_salary_to &&
      data.expectation_annual_salary_from > data.expectation_annual_salary_to
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Annual salary to" must be greater than "Annual salary from".',
        path: ['expectation_annual_salary_to'],
      });
    }
    if (
      data.expectation_day_rate_from &&
      data.expectation_day_rate_to &&
      data.expectation_day_rate_from > data.expectation_day_rate_to
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Day rate to" must be greater than "Day rate from".',
        path: ['expectation_day_rate_to'],
      });
    }
    if (
      data.expectation_hourly_rate_from &&
      data.expectation_hourly_rate_to &&
      data.expectation_hourly_rate_from > data.expectation_hourly_rate_to
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Hourly rate to" must be greater than "Hourly rate from".',
        path: ['expectation_hourly_rate_to'],
      });
    }
  });

export type TExpectationsFormData = z.infer<typeof expectationsFormSchema>;
