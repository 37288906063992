import * as React from 'react';
import { UilCheck } from '@iconscout/react-unicons';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const checkBoxVariants = cva(
  'peer shrink-0 border border-primary-dark-20 shadow-sm focus-visible:border-primary-blue-40 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-blue-10 hover:enabled:border-primary-blue-120 hover:enabled:bg-primary-blue-10 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        outline:
          'rounded-sm data-[state=checked]:border-primary-blue-120 data-[state=checked]:bg-primary-blue-10 data-[state=checked]:text-primary-blue-120',
        filled:
          'rounded-full data-[state=checked]:border-primary-blue-120 data-[state=checked]:bg-primary-blue-120 data-[state=checked]:text-primary-white-100 hover:enabled:data-[state=checked]:bg-primary-blue-120',
      },
      size: {
        sm: 'size-4',
        lg: 'size-5',
      },
    },
    defaultVariants: {
      variant: 'outline',
      size: 'lg',
    },
  }
);

const iconVariants = cva('', {
  variants: {
    size: {
      sm: 'size-3.5',
      lg: 'size-4',
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});

const labelVariants = cva('font-medium text-primary-dark-120 aria-disabled:text-primary-dark-20', {
  variants: {
    size: {
      sm: 'text-sm',
      lg: 'text-lg',
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkBoxVariants> {
  label?: string;
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, variant, size, label, id, disabled, ...props }, ref) => (
    <div className={cn('inline-flex items-center gap-x-3', className)}>
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(checkBoxVariants({ variant, size }))}
        {...props}
        disabled={disabled}
      >
        <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
          <UilCheck className={cn(iconVariants({ size }))} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      <label
        htmlFor={id}
        className={cn(labelVariants({ size }))}
        aria-disabled={disabled}
      >
        {label}
      </label>
    </div>
  )
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
