import { useState } from 'react';
import { UilCheck } from '@iconscout/react-unicons';
import { subHours } from 'date-fns';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { format, formatDate } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { Event, View } from 'react-big-calendar';

import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Popover, PopoverClose, PopoverContent, PopoverPortal, PopoverTrigger } from '@/components/ui/popover';

import { TBookedTimeSlot } from '@/services/candidate';

import { cn } from '@/lib/utils';

import RescheduleButton from './reschedule-button';

export interface ICandidateEvent extends Event {
  data: TBookedTimeSlot;
}
interface ICustomEventProps {
  view: View;
  event: ICandidateEvent;
}

const EventPopoverContent: React.FC<{ event: ICandidateEvent }> = ({ event }) => {
  if (!event.start || !event.end) {
    return null;
  }

  // Format strings
  const dayAndDateFormat = 'EEEE, MMMM d'; // Format for day and date
  const timeFormat = 'h:mm a'; // Format for time in 12-hour format

  // Format the start date
  const formattedDayAndDate = format(event.start, dayAndDateFormat);
  const formattedStartTime = format(event.start, timeFormat);

  // Format the end time
  const formattedEndTime = format(event.end, timeFormat);

  // Combine the formatted strings
  const formattedString = `${formattedDayAndDate} • ${formattedStartTime} - ${formattedEndTime}`;

  const hasPassed = isBefore(subHours(event.start, 1), new Date());

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-semibold">Interview</h3>
        <PopoverClose />
      </div>
      <div className="flex gap-4">
        <div className="flex size-[1.875rem] min-h-[1.875rem] min-w-[1.875rem] items-center justify-center rounded bg-primary-success-100">
          <UilCheck className="size-5 text-primary-white-100" />
        </div>
        <div className="max-w-[80%] font-medium 3xl:max-w-[85%]">
          <h6 className="truncate text-xl text-primary-dark-100">{event.title}</h6>
          <p className="truncate text-primary-dark-60">{formattedString}</p>
        </div>
      </div>
      <div className="space-y-4">
        <p className="font-medium text-primary-dark-60">This interview is hosted by:</p>
        <div className="flex items-center gap-4">
          <Avatar fallbackType="company" />
          <span className="text-xl font-semibold text-primary-dark-100">{event?.data?.post?.company_name}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-4 pt-4">
        <RescheduleButton
          postId={event.data.post_id!}
          disabled={hasPassed}
          companyName={event.data.post?.company_name || ''}
        />
        <Button className="w-full">Join Meeting</Button>
      </div>
    </div>
  );
};

const CandidateEvent: React.FC<ICustomEventProps> = ({ event, view }) => {
  const [open, setOpen] = useState(false);

  if (!event.start || !event.end) {
    return null;
  }

  const isMeetingMoreThan15Mins = differenceInMinutes(event.end, event.start) > 15;

  const showTime = view === 'day' || view === 'week';

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
    >
      <PopoverTrigger
        className={cn(
          'flex h-full w-full gap-2 truncate rounded-lg bg-primary-success-10 ring-offset-white transition-colors data-[state=open]:bg-primary-success-20 hover:bg-primary-success-20 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-success-100',
          view === 'month' ? 'p-1' : 'p-2',
          (!showTime || !isMeetingMoreThan15Mins) && 'items-center'
        )}
      >
        <div className="flex size-[1.125rem] min-h-[1.125rem] min-w-[1.125rem] items-center justify-center rounded bg-primary-success-100">
          <UilCheck className="size-3 text-primary-white-100" />
        </div>
        <div className="max-w-[80%] text-start text-xs font-medium 3xl:max-w-[85%]">
          <h6 className={cn('truncate text-primary-dark-100', showTime && !isMeetingMoreThan15Mins && 'mt-0.5')}>
            {event.title}
            {showTime && !isMeetingMoreThan15Mins && (
              <span className="text-primary-dark-60">
                • {formatDate(event.start, 'h:mm a')} {'-'} {formatDate(event.end, 'h:mm a')}
              </span>
            )}
          </h6>
          {showTime && isMeetingMoreThan15Mins && (
            <p className="truncate text-primary-dark-60">
              {formatDate(event.start, 'h:mm a')} {'-'} {formatDate(event.end, 'h:mm a')}
            </p>
          )}
        </div>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          className="min-w-[32.75rem] rounded-2xl p-8 shadow-2xl"
          side={view === 'day' ? 'bottom' : 'right'}
        >
          <EventPopoverContent event={event} />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

export default CandidateEvent;
