import Avatar from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';
import { Tag } from '@/components/ui/tag';

import { AU } from '@/components/icons/flags';
import MatchPercentLabel from '@/components/tags/match-percent-label';
import {
  jobStatusToLabelMap,
  jobStatusToTagVariantMap,
  statusToLabelMap,
  statusToTagVariantMap,
} from '@/components/tags/match-status-tag';

import { TCandidateJobPost } from '@/services/candidate';

import { stringToDate } from '@/utils/format';

import { REMOTE_WORK_OPTIONS } from '@/constants/post';

import { CTA } from './cta';

export function SidePanel({ post }: { post: TCandidateJobPost }) {
  const candidateStatus = post?.metadata?.candidate_status;

  const candidateStatusLabel = candidateStatus ? statusToLabelMap[candidateStatus] : jobStatusToLabelMap[post.status];
  const candidateStatusVariant = candidateStatus
    ? statusToTagVariantMap[candidateStatus]
    : jobStatusToTagVariantMap[post.status];

  const city = post.city || post.business.city;
  const country = post.country || post.business.country;

  const legacyLocation = post.location || post.business.location;

  const locationString = city && country ? `${city}, ${country}` : legacyLocation;

  return (
    <Card className="flex w-full flex-col space-y-5 bg-primary-white-100 p-8 shadow-md">
      <div className="inline-flex flex-row items-center space-x-4">
        <Avatar
          fallbackType="company"
          src={post.business.logo_url}
          size="lg"
        />
        <h1 className="text-2xl font-semibold leading-loose text-primary-dark-100 lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl">
          {post.business.name}
        </h1>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center text-primary-dark-40">
          <h2 className="text-base font-semibold leading-normal">Match score</h2>
        </div>
        <div>
          <MatchPercentLabel
            className="text-md"
            percent={post.metadata?.match_percentage || 0}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center text-primary-dark-40">
          <h2 className="text-base font-semibold leading-normal">Role status</h2>
        </div>
        <div>
          <Tag
            size="md"
            variant={candidateStatusVariant}
          >
            {candidateStatusLabel}
          </Tag>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center text-primary-dark-40">
          <h2 className="text-base font-semibold leading-normal">Role</h2>
        </div>
        <p className="text-md font-semibold leading-7">{post.title}</p>
        <div className="flex flex-wrap space-x-2">
          <Tag
            size="md"
            variant="primary"
          >
            {post.remote_work === REMOTE_WORK_OPTIONS.YES ? 'Remote' : 'On-site'}
          </Tag>
          <Tag
            size="md"
            variant="primary"
          >
            {post.employment_type}
          </Tag>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center text-primary-dark-40">
          <h2 className="text-base font-semibold leading-normal">Day rate range (Base)</h2>
        </div>
        <p className="text-md font-semibold leading-7">{post.salary}</p>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center text-primary-dark-40">
          <h2 className="text-base font-semibold leading-normal">Location</h2>
        </div>
        <div>
          <Tag
            hideDot
            size="md"
          >
            <AU />
            {locationString}
          </Tag>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center text-primary-dark-40">
          <h2 className="text-base font-semibold leading-normal">Industry</h2>
        </div>
        <div>
          <Tag
            size="md"
            hideDot
          >
            {post.business.industry}
          </Tag>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center text-primary-dark-40">
          <h2 className="text-base font-semibold leading-normal">Job created</h2>
        </div>
        <p className="text-md font-semibold leading-7">{stringToDate(post.created_at as string)}</p>
      </div>

      <CTA
        candidateStatus={candidateStatus}
        post={post}
      />
    </Card>
  );
}
