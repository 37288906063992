import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

import Spinner from './spinner';

export const buttonVariants = cva(
  'inline-flex cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-lg text-sm font-semibold shadow-xs ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-blue-10 disabled:cursor-not-allowed disabled:opacity-45 focus-visible:disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default: 'bg-primary-blue-100 text-white focus-visible:ring-primary-blue-10 hover:enabled:bg-primary-blue-120',
        destructive: 'bg-primary-alert-100 text-white hover:bg-primary-alert-120 focus-visible:ring-primary-alert-10',
        tertiary:
          'border border-primary-dark-20 bg-white hover:text-primary-dark-100 focus-visible:ring-primary-dark-10 hover:enabled:bg-primary-dark-5',
        secondary:
          'bg-primary-blue-10 text-primary-blue-100 hover:bg-primary-blue-20 focus-visible:ring-primary-blue-20',
        ghost: '!px-0 !py-0 shadow-none hover:bg-primary-white-100', // todo
        link: 'text-primary-blue-100 shadow-none hover:text-primary-blue-120', // todo
      },
      size: {
        sm: 'h-[2.75rem] px-4 py-3',
        md: 'h-12 px-5 py-3',
        lg: 'rounded-md px-7 py-4',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'sm',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  loadingText?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, loading, loadingText, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={loading || props.disabled}
        {...props}
      >
        {loading ? (
          <>
            <Spinner
              variant={variant === 'tertiary' ? 'default' : 'secondary'}
              size="xs"
              className={loadingText ? 'mr-2' : ''}
            />
            {loadingText && loadingText}
          </>
        ) : (
          props.children
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button };
