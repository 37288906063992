import { useRef, useState } from 'react';
import { UilAngleDown, UilCheck, UilTimes } from '@iconscout/react-unicons';

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';

import { cn } from '@/lib/utils';

import { Badge } from './badge';
import { inputVariants } from './input';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export type OptionType = {
  value: string;
};

interface MultiSelectProps {
  options: string[];
  selected?: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  error?: boolean;
  enableSearch?: boolean;
  isLoading?: boolean;
  maxSelections?: number;
}

export function MultiSelect({
  error,
  options,
  selected = [],
  onChange,
  placeholder = 'Select one or more',
  enableSearch,
  isLoading,
  maxSelections,
  ...props
}: MultiSelectProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const handleSelect = (value: string) => {
    if (enableSearch) {
      inputRef?.current?.focus();
    }

    // Check if the selected value is already in the list
    const isAlreadySelected = selected.includes(value);

    // Check if we have reached the max selection limit
    const canSelectMore = !isAlreadySelected && (!maxSelections || selected.length < maxSelections);

    if (canSelectMore || isAlreadySelected) {
      const newValues = isAlreadySelected ? selected.filter((v) => v !== value) : [...selected, value];
      onChange(newValues);
    }

    setOpen(false);
  };

  const isSelected = (option: string) => {
    return selected.includes(option);
  };
  const sortedOptions = [...options].sort((a, b) => a.localeCompare(b));

  return (
    <div>
      <Command {...props}>
        <Popover
          open={open}
          onOpenChange={setOpen}
        >
          <PopoverTrigger asChild>
            {enableSearch ? (
              <CommandInput
                placeholder={placeholder}
                error={error}
                ref={inputRef}
              />
            ) : (
              <button
                className={cn(
                  inputVariants({ variant: error ? 'error' : 'default' }),
                  'relative justify-between text-primary-dark-60 [&[data-state=open]>svg]:rotate-180'
                )}
              >
                <span>{placeholder}</span>
                <UilAngleDown
                  className={cn('pointer-events-none size-6 text-primary-dark-60 transition-transform duration-200')}
                />
              </button>
            )}
          </PopoverTrigger>
          <PopoverContent
            onOpenAutoFocus={(e) => e.preventDefault()}
            className="PopoverContent p-0"
            asChild
            sideOffset={8}
          >
            <CommandList className="max-w-full bg-primary-white-100">
              <CommandEmpty>{isLoading ? 'Loading...' : 'No results found'}</CommandEmpty>
              <CommandGroup className="p-0">
                {sortedOptions.map((o) => (
                  <CommandItem
                    className="inline-flex w-full items-center justify-between"
                    key={o}
                    onSelect={() => handleSelect(o)}
                    isSelected={isSelected(o)}
                    disabled={maxSelections !== undefined && selected.length >= maxSelections && !isSelected(o)}
                  >
                    <span>{o}</span>
                    <span>{isSelected(o) && <UilCheck className="size-5 text-primary-blue-100" />}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </PopoverContent>
        </Popover>
      </Command>
      {selected?.length > 0 && (
        <div className="mt-2 flex flex-row flex-wrap gap-2">
          {selected.map((s) => (
            <Badge
              key={s}
              variant="success"
              hideDot
              onClick={() => handleSelect(s)}
              className="cursor-pointer"
            >
              <span>{s}</span>
              <UilTimes className="mt-0.5 size-3.5 text-primary-success-80" />
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
}
