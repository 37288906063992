import { Arrow, TooltipPortal } from '@radix-ui/react-tooltip';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts';

import { CardContent } from '@/components/ui/card';
import { ChartConfig, ChartContainer } from '@/components/ui/chart';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { CultureName, TCandidateCulture } from '@/services/candidate';
import { TCultureDescription } from '@/services/values-map';

interface Factor extends TCultureDescription {
  code: string;
}
interface CultureChartProps {
  data: TCandidateCulture[];
  data2?: TCandidateCulture[];
  factors: Factor[];
}

export const CultureChart: React.FC<CultureChartProps> = ({ data, data2, factors }) => {
  const order: CultureName[] = [CultureName.ADHOCRACY, CultureName.MARKET, CultureName.HIERARCHY, CultureName.CLAN];

  const chartData: { [key: string]: number | string }[] = data
    .sort((a, b) => {
      return order.indexOf(a.name) - order.indexOf(b.name);
    })
    .map((culture) => ({
      culture: culture.name,
      value: culture.score,
    }));

  if (data2) {
    data2
      .sort((a, b) => {
        return order.indexOf(a.name) - order.indexOf(b.name);
      })
      .forEach((culture, index) => {
        chartData[index]['value2'] = culture.score;
      });
  }

  const chartConfig = {
    value: {
      label: 'Your Results',
    },
    average: {
      label: 'Average',
    },
  } satisfies ChartConfig;

  const tickDimensions = [
    { width: 135, height: 32 },
    { width: 105, height: 32 },
    { width: 125, height: 32 },
    { width: 82, height: 32 },
  ];
  const tickPositions = [
    { x: 0, y: tickDimensions[0].height }, //+x moves left, +y moves up , -x moves right -y moves down
    { x: 0, y: 0 },
    { x: tickDimensions[2].width, y: -5 },
    { x: tickDimensions[3].width, y: tickDimensions[0].height },
  ];
  const tickDotColors = ['#FFDA53', '#D29CFE', '#2CB49E', '#3E60FF'];

  const getToolTipContent = (index: number) => {
    const codes = ['A', 'M', 'H', 'C'];
    const factor = factors.find((factor) => factor.code === codes[index]);
    return factor;
  };

  return (
    <CardContent className="h-full w-full">
      <ChartContainer
        config={chartConfig}
        className="h-full w-full"
      >
        <RadarChart
          data={chartData}
          dataKey={'culture'}
          outerRadius={'115%'}
          startAngle={45}
          endAngle={-315}
          id="culture-chart"
        >
          <PolarRadiusAxis
            domain={[-1, 5]}
            tickLine={false}
            tick={false}
            axisLine={false}
          />
          <PolarGrid
            strokeWidth={1}
            stroke="#CED3DB"
            fillOpacity={0.3}
            className="fill-primary-dark-5"
          />
          <Radar
            dataKey="value"
            fillOpacity={0}
            stroke="#3E60FF" //primary-blue-100
            strokeWidth={4}
            isAnimationActive={false}
          />
          {data2 && (
            <Radar
              dataKey="value2"
              fillOpacity={0}
              stroke="#2CB49E" // primary-green-100
              strokeWidth={4}
              strokeDasharray={6}
              isAnimationActive={false}
            />
          )}
          <PolarAngleAxis
            className="w-full"
            dataKey="culture"
            tick={({ x, y, index }) => {
              const data = chartData[index];
              const tooltipContent = getToolTipContent(index);
              return (
                <TooltipProvider>
                  <Tooltip delayDuration={300}>
                    <TooltipTrigger asChild>
                      <g className="cursor-pointer">
                        <rect
                          x={x - tickPositions[index].x}
                          y={y - tickPositions[index].y}
                          width={tickDimensions[index].width}
                          height={tickDimensions[index].height}
                          rx="6"
                          fill="#F3F4F6"
                        />
                        <rect
                          x={x - tickPositions[index].x}
                          y={y - 0.5 - tickPositions[index].y}
                          width={tickDimensions[index].width - 1}
                          height={tickDimensions[index].height - 1}
                          rx="5.5"
                          stroke="#E7E9ED"
                          fill="white"
                        />
                        <circle
                          cx={x + 16 - tickPositions[index].x}
                          cy={y + 16 - tickPositions[index].y}
                          r="3"
                          fill={tickDotColors[index]}
                        />
                        <text
                          x={x + 25 - tickPositions[index].x}
                          y={y + 22 - tickPositions[index].y}
                          textAnchor="start"
                          fontSize="18px"
                          stroke="#03122E"
                          strokeWidth={0.5}
                        >
                          {data.culture}
                        </text>
                      </g>
                    </TooltipTrigger>
                    <foreignObject
                      x={x - tickPositions[index].x - (160 - tickDimensions[index].width / 2)}
                      y={y - tickPositions[index].y + 32}
                      overflow={'visible'}
                    >
                      <TooltipPortal>
                        <TooltipContent
                          collisionBoundary={document.getElementById('culture-chart')}
                          className="w-[320px] border-none shadow-[0px_12px_16px_-6px_rgba(0,0,0,0.08)]"
                          avoidCollisions
                          side={[0, 3].includes(index) ? 'bottom' : 'top'}
                        >
                          {tooltipContent && (
                            <>
                              <p className="mb-1 text-left text-xs font-semibold leading-[18px] text-primary-dark-100">
                                {tooltipContent.name}
                              </p>
                              <p className="text-left text-xs font-medium leading-[18px] text-primary-dark-60">
                                Thrusts: {tooltipContent.thrust}
                                <br /> <br />
                                Means: {tooltipContent.means}
                                <br /> <br />
                                Ends: {tooltipContent.ends}
                              </p>
                              <br />
                              <ul className="list-disc pl-4">
                                {tooltipContent.extras.map((facet) => (
                                  <li
                                    className="text-left text-xs font-medium leading-[18px] text-primary-dark-60"
                                    key={facet}
                                  >
                                    {facet}
                                  </li>
                                ))}
                              </ul>
                              <Arrow
                                width={16}
                                height={6}
                                className="fill-white"
                              />
                            </>
                          )}
                        </TooltipContent>
                      </TooltipPortal>
                    </foreignObject>
                  </Tooltip>
                </TooltipProvider>
              );
            }}
          />
        </RadarChart>
      </ChartContainer>
    </CardContent>
  );
};
