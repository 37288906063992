import client, { TRequestOptions } from '@/lib/api';

import { CANDIDATE_ENDPOINTS } from './candidate.endpoints';
import {
  TCandidate,
  TGetBookedTimeslotsRequest,
  TGetCurrentCandidateAppliedMatchesRequest,
  TGetCurrentCandidateArchivedJobPosts,
  TGetCurrentCandidateHiredMatchesRequest,
  TGetCurrentCandidateMatchesRequest,
  TGetCurrentCandidateRequest,
  TGetCurrentCandidateSingleJobDetailsRequest,
  TGetSavedPostRequest,
  TSavePostRequest,
  TUnsavePostRequest,
  TUpdateCurrentCandidateRequest,
} from './candidate.types';

const { GET_CURRENT_CANDIDATE, UPDATE_CURRENT_CANDIDATE, GET_BOOKED_TIMESLOTS } = CANDIDATE_ENDPOINTS;

export const getCurrentCandidate = async ({ signal }: TRequestOptions<TGetCurrentCandidateRequest>) => {
  const { data } = await client.GET(GET_CURRENT_CANDIDATE, {
    signal,
  });

  return data?.data;
};

export const updateCurrentCandidate = async ({ signal, body }: TRequestOptions<TUpdateCurrentCandidateRequest>) => {
  await client.PUT(UPDATE_CURRENT_CANDIDATE, { signal, body });
};

const checkIsExpectionFieldsValid = (candidate: TCandidate) => {
  const isAnnualSalaryEmpty = !candidate?.expectation_annual_salary_from && !candidate?.expectation_annual_salary_to;
  const isDayRateEmpty = !candidate?.expectation_day_rate_from && !candidate?.expectation_day_rate_to;
  const isHourlyRateEmpty = !candidate?.expectation_hourly_rate_from && !candidate?.expectation_hourly_rate_to;
  const isOtherExpectationsEmpty = !candidate?.other_expectations || candidate.other_expectations.length === 0;

  return !((isAnnualSalaryEmpty && isDayRateEmpty && isHourlyRateEmpty) || isOtherExpectationsEmpty);
};

const isAllCandidateFieldsEmpty = (candidate: TCandidate) => {
  return (
    !candidate?.looking_for &&
    !candidate?.business_size &&
    !candidate?.roles_interested &&
    !candidate?.experience_1_start_date &&
    !candidate?.experience_1_role &&
    !candidate?.experience_1_end_date &&
    !candidate?.technical_skills &&
    !candidate?.industry_experience &&
    !candidate?.business_experience &&
    !candidate?.domain_exp &&
    !candidate?.education &&
    !candidate?.english_proficiency &&
    !candidate?.country &&
    !candidate?.city &&
    !candidate?.residency_status &&
    !candidate?.bio &&
    !candidate?.skills &&
    !candidate?.candidate_values &&
    !candidate?.cv_url &&
    !candidate?.web_url &&
    !candidate?.linkedin_url &&
    !checkIsExpectionFieldsValid(candidate)
  );
};

export const getCandidateOnbaordingStatus = (candidate: TCandidate) => {
  if (isAllCandidateFieldsEmpty(candidate)) {
    return 0;
  }

  if (!candidate?.looking_for || !candidate.business_size || !candidate.roles_interested) return 1;

  const isExpectationValid = checkIsExpectionFieldsValid(candidate);
  if (!isExpectationValid) return 2;

  if (!candidate?.experience_1_compname || !candidate.experience_1_role || !candidate.experience_1_start_date) return 3;
  if (
    !candidate?.technical_skills ||
    !candidate.industry_experience ||
    !candidate.business_experience ||
    !candidate.domain_exp
  )
    return 4;
  if (!candidate?.education || !candidate.english_proficiency) return 5;
  if (!candidate.country || !candidate.city || !candidate.residency_status) return 6;
  if (!candidate.bio || !candidate.skills || !candidate.candidate_values) return 7;
  if (!candidate.linkedin_url) return 8;

  return 9;
};

export const hasCandidateCompletedCultureQuiz = (candidate: TCandidate) => {
  return !!candidate?.culture;
};

export const hasCandidateCompletedPersonalityQuiz = (candidate: TCandidate) => {
  if (!candidate?.personality_archetype || candidate.personality_archetype === '') {
    return false;
  }
  if (!candidate?.personality_test_info || candidate.personality_test_info === '') {
    return false;
  }
  return true;
};

export const getBookedTimeslots = async ({ signal, params }: TRequestOptions<TGetBookedTimeslotsRequest>) => {
  const { data } = await client.GET(GET_BOOKED_TIMESLOTS, {
    signal,
    params,
  });

  return data?.data?.items;
};

export const getCurrentCandidateMatches = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateMatchesRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_MATCHES, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentCandidateSingleJobDetails = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateSingleJobDetailsRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_SINGLE_JOB_POST, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentCandidateAppliedMatches = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateAppliedMatchesRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_APPLIED_MATCHES, {
    signal,
    params,
  });

  return data?.data;
};

export const getCurrentCandidateHiredMatches = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateHiredMatchesRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_HIRED_MATCHES, {
    signal,
    params,
  });

  return data?.data;
};

export const savePost = async ({ signal, params }: TRequestOptions<TSavePostRequest>) => {
  await client.POST(CANDIDATE_ENDPOINTS.SAVE_POST, {
    signal,
    params,
  });
};

export const unsavePost = async ({ signal, params }: TRequestOptions<TUnsavePostRequest>) => {
  await client.DELETE(CANDIDATE_ENDPOINTS.UNSAVE_POST, {
    signal,
    params,
  });
};

export const getCurrentCandidateArchivedJobPosts = async ({
  signal,
  params,
}: TRequestOptions<TGetCurrentCandidateArchivedJobPosts>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_ARCHIVED_JOB_POST, {
    signal,
    params,
  });

  return data?.data;
};

export const getSavedPost = async ({ signal, params }: TRequestOptions<TGetSavedPostRequest>) => {
  const { data } = await client.GET(CANDIDATE_ENDPOINTS.GET_SAVED_POST, {
    signal,
    params,
  });

  return data?.data;
};
