import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { View } from 'react-big-calendar';

import Heading from '@/components/ui/typography/heading';

import CandidateCalendar from '@/components/big-calendar/candidate/candidate-calendar';
import { ICandidateEvent } from '@/components/big-calendar/candidate/candidate-event';
import CalendarTabNav from '@/components/big-calendar/components/calendar-tab-nav';

import { useGetBookedTimeslots } from '@/hooks/candidate';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/calendar')({
  component: CalendarPage,
});

function CalendarPage() {
  const [view, setView] = useState<View>('month');
  const { data: timeslots, isLoading } = useGetBookedTimeslots({});

  const events: ICandidateEvent[] =
    timeslots
      ?.filter((timeslot) => !!timeslot)
      ?.map((timeslot) => ({
        start: new Date(timeslot?.slot_start_time || 0),
        end: new Date(timeslot?.slot_end_time || 0),
        title: timeslot?.post?.title || '',
        data: timeslot,
      })) || [];

  return (
    <div className="space-y-6">
      <Heading
        title="Calendar"
        size="page"
      />
      <CalendarTabNav
        view={view}
        onChangeView={setView}
      />
      <CandidateCalendar
        view={view}
        onChangeView={setView}
        events={events}
        isLoading={isLoading}
      />
    </div>
  );
}
